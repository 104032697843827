<template>
    <v-container fluid>
        <v-textarea
            v-model="note.note"
            filled
            clear-icon="mdi-close-circle"
            label="Add Notes"
        ></v-textarea>
        <div class="d-flex justify-end">
            <v-btn @click="saveNote"  depressed dark color="accent" right>
                Save
            </v-btn>
        </div>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'

export default {
    data: () => ({
    tab: null,
    note: {
        companyId: 0,
        noteTypeId: 0,
        note: "",
        userId: 0,
    }
  }),
  computed: {
    ...mapGetters("notes", ["noteTypes"]),
    ...mapGetters("company", ["companyId"]),
    ...mapGetters("employee", ["userId", "userName"]),
  },
  methods: {
    ...mapActions("notes", ["createNotes"]),

    async saveNote(){
        if(this.note.note === "") {
            return false
        }
        
        const noteDetails = {
            companyId: this.companyId,
            noteTypeId: 2,
            note: this.note.note,
            userId: this.userId,
            createdByEmployeeId: this.userId,
            createdDate: dayjs().unix(),
            description: this.note.note,
            employeeName: this.userName,
            icon: "account",
            noteId: 0,
            noteType:"user",
            profilePicFileName: `https://crm.utilityhive.com//img/ProfilePics/${this.userId}.jpg`,
            saleId:0
        }
        const res = await this.createNotes(noteDetails)
        if(res.status === 200) {
            Swal.fire({
                background: '#E6F5E9',
                toast: true,
                html:
                '<i class="fas fa-check fa-lg" style="color:#4B908C"></i> <br> <h3 style="color:#4B908C; margin-top: 0.8rem;">Note Successfully Saved!</h3>',
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                width: 300,
            })
            this.note.note = ""
        }
    }
  }
}
</script>