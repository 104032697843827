const isPlural = qty => (parseInt(qty) > 1 ? "s" : "")

const profilePicture = userId =>
  `${process.env.VUE_APP_ASSETS_URL}/ProfilePics/${userId}.jpg`

const onProfilePictureError = event =>
  (event.target.src = `${process.env.VUE_APP_ASSETS_URL}/ProfilePics/default-avatar.png`)

const supplierLogo = supplierId =>
  `${process.env.VUE_APP_ASSETS_URL}/SupplierLogos/${supplierId}.jpg`

export default {
  isPlural,
  profilePicture,
  supplierLogo,
  onProfilePictureError
}
