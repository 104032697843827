/* eslint-disable vue/no-async-in-computed-properties */
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="indigo"
          depressed
          icon
          small
          @click="submitContractOpened"
        >
          <v-icon small>mdi-file-send</v-icon>
        </v-btn>
      </template>
      <v-card flat>
        <v-card-title>
          <v-row class="d-flex justify-space-between" align="center">
            <v-col cols="auto">
              <span class="headline">Submit Contract</span>
            </v-col>
            <v-col class="d-flex" cols="4.5">
              <v-container class="d-flex">
                <div class="mr-2">
                  <v-avatar color="primary" size="36">
                    <img
                      v-if="leadGeneratorDetails"
                      :src="
                        $helpers.profilePicture(
                          leadGeneratorDetails.readyForQuoteFrom
                        )
                      "
                      :alt="leadGeneratorDetails.readyForQuoteFromName"
                      @error="$helpers.onProfilePictureError"
                    />
                  </v-avatar>
                </div>
                <div>
                  <div class="generator">Lead Generator</div>

                  <div class="text-title">
                    {{ leadGeneratorDetails.readyForQuoteFromName }}
                  </div>
                </div>
              </v-container>
              <v-container class="d-flex">
                <div class="mr-2">
                  <v-avatar color="primary" size="36">
                    <img
                      v-if="employeeDetails && details.dataSourceId !== 2725"
                      :src="
                        $helpers.profilePicture(readyForQuoteData.assignedById)
                      "
                      :alt="readyForQuoteData.assignedById"
                      @error="$helpers.onProfilePictureError"
                    />
                    <img
                      v-else-if="details.dataSourceId === 2725 && userId"
                      :src="$helpers.profilePicture(userId)"
                      :alt="userId"
                      @error="$helpers.onProfilePictureError"
                    />
                  </v-avatar>
                </div>
                <div>
                  <div class="generator">Closer</div>
                  <div
                    class="text-title"
                    v-if="employeeDetails && details.dataSourceId !== 2725"
                  >
                    {{ readyForQuoteData.assignedByName }}
                  </div>
                  <div
                    class="text-title"
                    v-if="details.dataSourceId === 2725 && userName"
                  >
                    {{ userName }}
                  </div>
                </div>
              </v-container>
            </v-col>
            <v-col>
              <v-row
                no-gutters
                align="center"
                style="
                  justify-content: center;
                  font-size: 0.875rem;
                  color: #000000de;
                "
              >
                <v-col
                  v-if="details.dataSourceId === 2725"
                  cols="auto"
                  class="px-3"
                >
                  <v-btn-toggle
                    :background-color="
                      showCampaignError && !company ? 'red' : ''
                    "
                    :value="company"
                    :color="companyColor"
                    borderless
                  >
                    <v-btn
                      value="sub broker"
                      :class="{
                        'v-btn--active v-item--active':
                          company === 'sub broker',
                      }"
                    >
                      SUB BROKER
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col v-else cols="auto" class="px-3">
                  <v-btn-toggle
                    :background-color="
                      showCampaignError && !company ? 'red' : ''
                    "
                    :value="company"
                    :color="companyColor"
                    borderless
                  >
                    <v-btn
                      value="utility hive"
                      @click="UHCampaignClicked"
                      :disabled="!employeeCampaign.utilityHive"
                      :class="UHClasses"
                    >
                      Utility Hive
                    </v-btn>
                    <v-btn
                      value="commercial support service"
                      @click="CSSCampaignClicked"
                      :disabled="!employeeCampaign.css"
                      :class="CSSClasses"
                    >
                      Commercial Support Service
                    </v-btn>
                  </v-btn-toggle>
                  <span v-if="showCampaignError && !company" class="mt-2">
                    <span class="red--text"> You must choose a campaign.</span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <!-- <v-col cols="4">
                    <v-select label="Campaign" v-model="selectedCampaign" :items="campaigns" @change="getSupplierUplift"></v-select>
                </v-col> -->
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2" depressed>
          <v-container>
            <v-row :class="companyColor">
              <v-col cols="4">
                <div class="mb-2 white--text">SUPPLY NUMBER</div>
                <SupplyNumber
                  :type="selectedMeter.meterType"
                  :supply-number="selectedMeter.supplyNumber"
                  :pc="selectedMeter.pc"
                  :mtc="selectedMeter.mtc"
                  :llf="selectedMeter.llf"
                  :disabled="true"
                ></SupplyNumber>
              </v-col>
              <v-col class="ml-12">
                <div class="mb-2 white--text">CONTRACT TYPE</div>
                <v-btn-toggle
                  :mandatory="firstTime"
                  v-model="contractType"
                  @change="validateContractType"
                  :background-color="contractTypeValidationError ? 'red' : ''"
                  tile
                >
                  <!-- <v-btn>
                    <v-icon color="primary">mdi-rotate-right</v-icon>
                  </v-btn> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- v-if="masterContractType === 1" -->
                      <!-- <v-icon v-bind="attrs" v-on="on" color="primary ml-3" style="font-size:20px;">mdi-information</v-icon> -->
                      <v-btn>
                        <v-icon v-bind="attrs" v-on="on" color="primary"
                          >mdi-rotate-right</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>RENEWALS</span>
                  </v-tooltip>
                  <!-- <v-btn>
                    <v-icon color="primary">mdi-arrow-right</v-icon>
                  </v-btn> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-icon v-bind="attrs" v-on="on" color="primary ml-3" style="font-size:20px;">mdi-information</v-icon> -->
                      <v-btn>
                        <v-icon v-bind="attrs" v-on="on" color="primary"
                          >mdi-arrow-right</v-icon
                        >
                      </v-btn>
                    </template>
                    <span> ACQUISITION</span>
                  </v-tooltip>
                  <!-- <v-btn>
                    <v-icon color="primary">mdi-truck</v-icon>
                  </v-btn> -->
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <v-icon v-bind="attrs" v-on="on" color="primary ml-3" style="font-size:20px;">mdi-information</v-icon> -->
                      <v-btn>
                        <v-icon v-bind="attrs" v-on="on" color="primary"
                          >mdi-truck</v-icon
                        >
                      </v-btn>
                    </template>
                    <span> NEW MOVER</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-container>
          <v-stepper v-model="e1" depressed class="mt-2" alt-labels>
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">
                Company <br />
                Information
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">
                Credit <br />
                Score
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3">
                Address <br />Information
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 4" step="4">
                Payment <br />Information
              </v-stepper-step>
              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 5" step="5">
                Significant <br />Person
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 6" step="6">
                Credit <br />Information
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="e1 > 7" step="7">
                Contract Agreed <br />By
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="8">
                <span> Contract <br />Information </span>
              </v-stepper-step>
              <!-- <v-divider></v-divider> -->
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Company Name"
                          ref="companyName"
                          :rules="companyNameRules"
                          v-model="details.companyName"
                          @keyup="formatName"
                        ></v-text-field>

                        <v-autocomplete
                          ref="natureOfBusinessId"
                          :rules="natureOfBusinessIdRules"
                          :items="natureOfBusinessList"
                          color="white"
                          v-model="details.natureOfBusinessId"
                          item-text="value"
                          item-value="id"
                          label="Nature of Business"
                          clearable
                        ></v-autocomplete>
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="details.tradingTypeId"
                          ref="tradingTypeId"
                          :rules="tradingTypeIdRules"
                          :items="tradingTypeList"
                          item-text="value"
                          item-value="id"
                          label="Trading Type"
                          @change="tradingTypeChanged"
                        ></v-select>
                        <v-text-field
                          v-if="
                            details.tradingTypeId !== 6 &&
                            details.tradingTypeId !== 8
                          "
                          ref="registrationNo"
                          label="Registration Number"
                          :rules="registrationNoRules"
                          v-model="details.registrationNo"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn color="accent" @click="updateCompanyAndContinue">
                    Continue
                  </v-btn>
                  <!-- <v-btn
                    color="accent"
                    :disabled="isSubmitting"
                    @click="openEForm"
                  >
                    REVIEW & SEND CONTRACT 
                  </v-btn>         -->
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <v-container>
                    <v-btn-toggle
                      class="ml-5"
                      active-class="light-blue lighten-5"
                    >
                      <v-btn @click="experianLogoSelected(0)"> NONE </v-btn>
                      <v-btn @click="experianLogoSelected(1)">
                        <v-img width="65px" :src="creditSafeLogo"> </v-img>
                      </v-btn>
                      <v-btn @click="experianLogoSelected(2)">
                        <v-img width="65px" :src="experianLogo"> </v-img>
                      </v-btn>
                    </v-btn-toggle>
                    <span
                      v-if="showCreditSafeError"
                      class="d-block mt-2 ml-5 mb-3"
                    >
                      <span class="red--text">
                        You must choose a Credit Score.</span
                      >
                    </span>
                    <div v-if="selectedCreditMode === 1">
                      <v-form class="pa-5" ref="form" lazy-validation>
                        <!-- {{details}} -->
                        <v-text-field
                          ref="creditSafeCompanyName"
                          :rules="creditSafeCompanyNameRules"
                          label="Credit Safe Company Name"
                          prepend-icon="mdi-credit-card"
                          required
                          v-model="creditSafeCompanyName"
                        ></v-text-field>
                        <v-text-field
                          ref="creditScore"
                          :rules="creditScoreRules"
                          label="Credit Score"
                          prepend-icon="mdi-chart-arc"
                          @keypress="NoLettersAccepted"
                          required
                          v-model="creditScore"
                        ></v-text-field>
                        <v-text-field
                          ref="creditLimit"
                          :rules="creditLimitRules"
                          label="Credit Limit"
                          prepend-icon="mdi-currency-gbp"
                          @keypress="NoLettersAccepted"
                          v-model="creditLimit"
                        ></v-text-field>
                        <v-text-field
                          ref="creditSafeID"
                          :rules="creditSafeIDRules"
                          prepend-icon="mdi-credit-card-check"
                          label="Credit Safe ID"
                          v-model="details.creditSafeID"
                        ></v-text-field>
                      </v-form>
                    </div>
                    <div v-if="selectedCreditMode === 2">
                      <v-form class="pa-5" ref="form2" lazy-validation>
                        <!-- {{details}} -->
                        <v-text-field
                          ref="experianCompanyName"
                          :rules="experianCompanyNameRules"
                          label="Experian Company Name"
                          prepend-icon="mdi-credit-card"
                          required
                          v-model="experianCompanyName"
                        ></v-text-field>
                        <v-text-field
                          ref="experianScore"
                          :rules="experianScoreRules"
                          label="Experian Score"
                          prepend-icon="mdi-chart-arc"
                          @keypress="NoLettersAccepted"
                          required
                          v-model="experianScore"
                        ></v-text-field>
                        <v-text-field
                          ref="experianLimit"
                          :rules="creditLimitRules"
                          label="Credit Limit"
                          prepend-icon="mdi-currency-gbp"
                          @keypress="NoLettersAccepted"
                          v-model="experianLimit"
                        ></v-text-field>
                      </v-form>
                    </div>
                  </v-container>
                  <v-btn class="mr-2" color="primary" @click="e1 = 1">
                    Back
                  </v-btn>
                  <v-btn color="accent" @click="updateCreditScoreAndContinue">
                    Continue
                  </v-btn>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          disabled
                          label="Site Name"
                          v-model="selectedAddress.siteName"
                        ></v-text-field>
                        <v-text-field
                          disabled
                          label="Address Line 1"
                          v-model="selectedAddress.add1"
                        ></v-text-field>
                        <v-text-field
                          disabled
                          label="Address Line 2"
                          v-model="selectedAddress.add2"
                        ></v-text-field>
                        <v-text-field
                          disabled
                          label="Town/City"
                          v-model="selectedAddress.town"
                        ></v-text-field>
                        <v-text-field
                          disabled
                          label="County"
                          v-model="selectedAddress.county"
                        ></v-text-field>
                        <v-text-field
                          disabled
                          label="Postcode"
                          v-model="selectedAddress.postcode"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn class="mr-2" color="primary" @click="e1 = 2">
                    Back
                  </v-btn>
                  <v-btn color="accent" @click="e1 = 4"> Continue </v-btn>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card>
                  <v-container>
                    <v-row>
                      <v-col cols="auto">
                        <v-select
                          label="Payment Method"
                          :items="paymentList"
                          v-model="payment.typeId"
                          @change="fetchContractInfoSuppliers"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="payment.typeId === 1">
                      <v-col>
                        <v-text-field
                          ref="bankName"
                          label="Bank Name"
                          :rules="bankNameRules"
                          v-model="payment.bankName"
                          @input="bankNameChanged"
                        ></v-text-field>
                        <v-text-field
                          ref="sortCode"
                          label="Sort Code"
                          @paste="handlePaste"
                          :rules="sortCodeRules"
                          v-model="payment.sortCode"
                          @keypress="isSortCodeNumberKey"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          ref="accountName"
                          label="Account Name"
                          :rules="accountNameRules"
                          v-model="payment.accountName"
                          @input="accountNameChanged"
                        ></v-text-field>
                        <v-text-field
                          ref="accountNumber"
                          label="Account Number"
                          @paste="handlePasteAccountNumber"
                          :rules="acountNumberRules"
                          v-model="payment.accountNumber"
                          @keypress="isAccountNoNumberKey($event)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn class="mr-2" color="primary" @click="e1 = 3">
                    Back
                  </v-btn>
                  <v-btn color="accent" @click="savePaymentAndContinue">
                    Continue
                  </v-btn>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="5">
                <v-card>
                  <v-container>
                    <v-row>
                      <v-col cols="auto">
                        <v-select
                          ref="significantPersonContactId"
                          :rules="significantPersonContactIdRules"
                          v-model="significantPersonContactId"
                          @change="onChange"
                          label="Significant Person"
                          :items="significantPersonsList"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="significantPersonContactId">
                      <v-col>
                        <v-select
                          label="Title"
                          :items="titleList"
                          v-model="selectedSignificantPerson.title"
                        ></v-select>
                        <v-text-field
                          label="Surname"
                          v-model="selectedSignificantPerson.lastName"
                          @input="lastNameCapitalLetter"
                        ></v-text-field>
                        <base-telephone-input
                          ref="contactNumber"
                          refName="contactNumber"
                          label="Contact Number"
                          :error-msg-c-c="errorMsgCC"
                          v-model="selectedSignificantPerson.contactNumber"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="First Name"
                          v-model="selectedSignificantPerson.firstName"
                          @input="firstNameCapitalLetter"
                        ></v-text-field>
                        <v-text-field
                          label="Job Role"
                          v-model="selectedSignificantPerson.jobTitle"
                          @input="jobTitleCapitalLetter"
                        ></v-text-field>
                        <v-text-field
                          label="Email"
                          v-model="selectedSignificantPerson.emailAddress"
                        ></v-text-field>
                        <!-- <v-text-field   label="Alternative Contact Number" ref="altContactNumber" v-model="selectedSignificantPerson.altContactNumber"  maxlength="11" minlength="10"></v-text-field> -->
                        <!-- <base-telephone-input 
                          ref="altContactNumber" 
                          :required="true" 
                          refName="altContactNumber" 
                          label="Alternative Contact Number" 
                          :error-msg-c-c="errorMsg" 
                          v-model="selectedSignificantPerson.altContactNumber" 
                        /> -->
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-btn class="mr-2" color="primary" @click="e1 = 4">
                    Back
                  </v-btn>
                  <v-btn
                    color="accent"
                    @click="validateSignificantPersonAndContinue"
                  >
                    Continue
                  </v-btn>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="6">
                <v-card>
                  <v-container style="padding-bottom: 0px">
                    <v-row>
                      <v-col cols="auto">
                        <v-menu
                          ref="menuDOB"
                          v-model="menuDOB"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="d-inline-block mr-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-calendar
                            </v-icon>
                            <v-text-field
                              type="tel"
                              class="d-inline-block mr-2 hello"
                              :style="{
                                width:
                                  contactAddresses &&
                                  contactAddresses.length >= 3
                                    ? '14.5rem'
                                    : 'initial',
                              }"
                              label="Date of Birth"
                              v-mask="'##/##/####'"
                              placeholder="DD/MM/YYYY"
                              ref="dobText"
                              :rules="dobTextRules"
                              v-model="dobText"
                              @keyup="dobTextChanged"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dob"
                            ref="picker"
                            :max="formattedMaxDateOfBirth"
                            locale="en-GB"
                            @change="dateOfBirthChanged"
                          ></v-date-picker>
                        </v-menu>
                        <div
                          class="v-text-field__details mb-3"
                          v-if="dirtOfBirthError"
                        >
                          <div
                            class="v-messages theme--light error--text"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                Provide Date Of Birth
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-slide-group
                        v-if="contactAddresses && contactAddresses.length > 0"
                      >
                        <v-slide-item
                          v-for="address in contactAddresses"
                          :key="address.addressId"
                          v-slot="{ toggle }"
                        >
                          <AddressWidget
                            class="mr-2"
                            :address-id="address.addressId.toString()"
                            :first-line="address.add1"
                            :second-line="address.add2"
                            :town="address.town"
                            :county="address.county"
                            :post-code="address.postcode"
                            :start-date="address.fromDate"
                            :end-date="address.untilDate"
                            no-actions
                            @click="toggle"
                            @editAddressClicked="
                              editAddressClicked(address.addressId)
                            "
                          ></AddressWidget>
                        </v-slide-item>
                      </v-slide-group>
                    </v-row>
                  </v-container>
                  <CreditInformation
                    v-on:selected-address-id="selectedAddressIdChanged"
                    @selected-home-address="selectedHomeAddressChanged"
                    :contact-id="selectedSignificantPerson.contactId"
                    :selectedAddressIdProp="selectedAddressId"
                    @fetchContactAddress="
                      fetchContactAddresses(selectedSignificantPerson.contactId)
                    "
                  ></CreditInformation>
                  <div
                    class="v-text-field__details mb-3"
                    v-if="contacatAddressCountError"
                  >
                    <div
                      class="v-messages theme--light error--text"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          Selected contact must have at least 1 home address
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-btn class="mr-2" color="primary" @click="e1 = 5">
                    Back
                  </v-btn>
                  <v-btn color="accent" @click="saveDOBAndContinue">
                    Continue
                  </v-btn>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="7">
                <v-card>
                  <v-container>
                    <v-row>
                      <v-col cols="4">
                        <v-select
                          ref="selectedContractFrom"
                          :rules="selectedContractFromRules"
                          label="Contract Form"
                          v-model="selectedContractFrom"
                          :items="contractTypesList"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          ref="selectedAgreedBy"
                          :rules="selectedAgreedByRules"
                          label="Agreed By"
                          v-model="selectedAgreedBy"
                          :items="significantPersonsList"
                          @change="getContactPassword"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4">
                        <v-text-field
                          ref="selecedSecurityPassword"
                          :rules="selecedSecurityPasswordRules"
                          label="Security Password"
                          v-model="selecedSecurityPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          ref="selectedContactNumber"
                          label="Contact Number"
                          v-model="selectedContactNumber"
                          :items="selectedContactContactNumbers"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-if="company === 'commercial support service'"
                        cols="8"
                      >
                        <p class="mb-0 gray--text">
                          <span class="font-weight-bold"
                            >PLEASE READ TO CUSTOMER:</span
                          >
                          <br />
                          The reason we set a password up with you is to use
                          until the supply goes live with your chosen supplier.
                          We have had stories of other suppliers calling
                          customers back after we have set them up pretending to
                          be us or a supplier telling them that it has failed
                          and they gather all the information I have just taken
                          from you in a clever way by saying that they are my
                          manager or even myself. They then set you up with them
                          which causes you to have a massive termination fee
                          from “Supplier”, and you end up being with a supplier
                          that is under investigation from ofgem the market
                          regulator. My advice is not speak to anyone that
                          cannot provide this password and don’t give this
                          password to anyone. If I need to call you back I will
                          quote this password to you, I will never ask for it
                          and neither would my manager. If someone calls you
                          about your energy from this point on, please ask for
                          the password we have set up, if they cannot quote
                          “Password” to you then hang up the phone as it will
                          more than likely be the scam I just told you about and
                          could cause serious implications to your business.
                        </p>
                      </v-col>
                      <v-col v-else cols="4"></v-col>
                      <v-col style="padding: 0">
                        <v-btn
                          v-if="selectedContactNumber !== null"
                          :color="companyColor"
                          text
                          style="padding: 0 9px !important"
                          @click="sendSMS"
                        >
                          SEND PASSWORD TO
                          {{
                            selectedContactNumber
                              ? selectedContactNumber
                              : "XXXXX"
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>

                    <!-- {{selectedContact ? selectedContact :'as'}} -->
                  </v-container>
                  <v-btn class="mr-2" color="primary" @click="e1 = 6">
                    Back
                  </v-btn>
                  <v-btn color="accent" @click="validateAndContinue">
                    Continue
                  </v-btn>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="8">
                <v-card>
                  <v-container>
                    <v-row
                      v-if="
                        masterContractType === 2 &&
                        this.company === 'utility hive' &&
                        meterTypeId !== 2705
                      "
                    >
                      <v-col style="line-height: 0px">
                        <label class="text-secondary">Acquisition Reason</label>
                        <v-select
                          v-model="acquisitionReasonId"
                          placeholder="Please Select"
                          ref="acquisitionReasonId"
                          :rules="acquisitionReasonRules"
                          :items="acquisitionReasons"
                          item-text="value"
                          item-value="id"
                        >
                        </v-select>
                      </v-col>
                      <!-- Fair Price Policy (FPP)  [   ]  tick box    -->
                      <v-col
                        v-if="company === 'utility hive'"
                        style="line-height: 0px"
                      >
                        <v-row class="mt-0">
                          <span
                            class="mr-2 ml-2"
                            style="
                              display: flex;
                              align-items: center;
                              font-size: 14px;
                            "
                            >Fair Price Policy (FPP)</span
                          >
                          <v-checkbox
                            v-model="fpp"
                            inset
                            color="primary"
                            label=""
                          ></v-checkbox>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="auto">
                        <v-select
                          ref="soldSupplierId"
                          :rules="soldSupplierIdRules"
                          v-if="supplierList && contractType !== 0"
                          :items="suppliers"
                          v-model="soldSupplierId"
                          label="Supplier"
                          @change="changeSuppliers"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              :src="supplierLogo(item.value)"
                              class="mr-2"
                              alt="File"
                              width="70"
                            />{{ item.text }}
                          </template>
                        </v-select>

                        <v-select
                          ref="soldSupplierId"
                          :rules="soldSupplierIdRules"
                          v-else
                          :items="renewSupplierList"
                          v-model="soldSupplierId"
                          label="Supplier"
                          @change="changeSuppliers"
                        >
                          <template v-slot:item="{ item }">
                            <img
                              :src="supplierLogo(item.value)"
                              class="mr-2"
                              alt="File"
                              width="70"
                            />{{ item.text }}
                          </template>
                        </v-select>
                      </v-col>
                      <v-col
                        v-if="soldSupplierId && meterTypeId !== 2705"
                        cols="auto"
                      >
                        <v-select
                          ref="selectedMeterType"
                          :rules="selectedMeterTypeRules"
                          label="Meter Type"
                          v-model="selectedMeterType"
                          :items="contractMeterTypesList"
                          @change="changeMeterType"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="selectedMeterType || meterTypeId === 2705">
                      <v-col>
                        <v-menu
                          ref="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              ref="csdText"
                              :rules="csdRules"
                              :readonly="true"
                              label="Contract Start Date"
                              placeholder="DD/MM/YYYY"
                              v-model="csdText"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="csd"
                            ref="picker"
                            :min="formattedMinContractEndDate"
                            :max="formattedMaxContractEndDate"
                            locale="en-GB"
                            @change="dateOfCSDChanged"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col>
                        <!-- <v-text-field
                          ref="termInMonths"
                          :rules="termInMonthsRules"
                          label="Term (Months)"
                          max="60"
                          type="number"
                          v-model="termInMonths"
                        ></v-text-field>
                        {{termInMonths}} -->
                        <v-select
                          ref="selectedTerm"
                          :rules="selectedTermRules"
                          label="Term (Months)"
                          v-model="termInMonths"
                          :items="TermTypesList"
                        ></v-select>
                      </v-col>
                      <v-col>
                        <v-text-field
                          disabled
                          label="Contract End Date"
                          v-model="ced"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-card
                      v-if="soldSupplierId && meterTypeId === 2705"
                      color="blue lighten-5"
                      flat
                    >
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <div>Current Retailer Price</div>
                              <v-text-field
                                ref="currentRetailerPrice"
                                :rules="currentRetailerPriceRules"
                                v-model="currentRetailerPrice"
                                prepend-icon="mdi-currency-gbp"
                                type="number"
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <div>Sold Price</div>
                              <v-text-field
                                ref="soldPrice"
                                :rules="soldPriceRules"
                                v-model="soldPrice"
                                type="number"
                                prepend-icon="mdi-currency-gbp"
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <div>Savings</div>
                              <v-text-field
                                :error="parseFloat(savings) < 0 ? true : false"
                                :class="
                                  parseFloat(savings) < 0
                                    ? 'water-red--text'
                                    : ''
                                "
                                prepend-icon="mdi-currency-gbp"
                                :value="savingsValue"
                                :disabled="
                                  parseFloat(savings) < 0 ? false : true
                                "
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>

                    <v-card
                      v-if="soldSupplierId && meterTypeId === 2705"
                      color="indigo lighten-5"
                      flat
                      class="mt-2"
                    >
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <div>Standard Commission</div>
                              <v-text-field
                                ref="standardCommission"
                                :rules="standardCommissionRules"
                                prepend-icon="mdi-currency-gbp"
                                v-model="standardCommission"
                                type="number"
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <div>Uplift Commission</div>
                              <v-text-field
                                ref="upliftCommission"
                                :rules="upliftCommissionRules"
                                prepend-icon="mdi-currency-gbp"
                                v-model="upliftCommission"
                                type="number"
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <div>Total Est Contract Value</div>
                              <v-text-field
                                v-model="waterTotalEstContractValue"
                                prepend-icon="mdi-currency-gbp"
                                disabled
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>

                    <v-row
                      v-if="csdText && termInMonths && meterTypeId !== 2705"
                    >
                      <v-col style="line-height: 0px">
                        <label class="text-secondary">EAC Obtained</label>
                        <v-select
                          v-model="eacOptionSelected"
                          ref="eacOptionSelected"
                          :rules="eacOptionsRules"
                          placeholder="Please Select"
                          :items="eacOptions"
                          item-text="value"
                          item-value="id"
                          @change="selectEAC"
                        >
                        </v-select>
                      </v-col>
                      <v-col v-if="eacOptionSelected !== 427">
                        <v-file-input
                          label="EAC Confirmed"
                          id="file"
                          v-model="eacConfirmedFile"
                          multiple="true"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        csdText &&
                        termInMonths &&
                        company === 'utility hive' &&
                        meterTypeId !== 2705
                      "
                    >
                      <v-col style="line-height: 0px">
                        <label class="text-secondary">CED Obtained</label>
                        <v-select
                          v-model="cedOptionSelected"
                          ref="cedOptionSelected"
                          :rules="cedOptionsRules"
                          placeholder="Please Select"
                          :items="cedOptions"
                          item-text="value"
                          item-value="id"
                        >
                        </v-select>
                      </v-col>

                      <v-col>
                        <v-file-input
                          label="CED Confirmed"
                          id="ced-file"
                          v-model="cedConfirmedFile"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <!-- <v-row
                      v-if="
                        this.company === 'utility hive' &&
                          eacOptionSelected !== 0 &&
                          eacOptionSelected !== 427
                      "
                    >
                    </v-row> -->
                    <v-row
                      v-if="csdText && termInMonths && meterTypeId !== 2705"
                    >
                      <v-container>
                        <v-switch
                          v-if="fixedComm === 1"
                          v-model="fixedCommission"
                          @change="fixedCommissionChanged"
                          inset
                          color="primary"
                          label="Fixed Commission"
                          :disabled="fixedComm === 1"
                        ></v-switch>
                      </v-container>
                    </v-row>
                    <v-row
                      v-if="csdText && termInMonths && meterTypeId !== 2705"
                    >
                      <v-container>
                        <FixedCommission
                          v-if="fixedCommission"
                          :changed-meter-type="changedMeterType"
                          :quote-type-price-list="quoteTypePriceList"
                        ></FixedCommission>
                        <StandingChargeCommission
                          :quote-type-price-list="quoteTypePriceList"
                          v-if="!fixedCommission"
                          :standing-charge-uplift="standingChargeUplift"
                          :sc-max-uplift="scMaxUplift"
                          :sc-inc-uplift="scIncUplift"
                          v-on:standing-charge-uplift-changed="
                            standingChargeUpliftChanged
                          "
                        ></StandingChargeCommission>
                        <UnitRateCommissions
                          class="mt-3"
                          :quote-type-price-list="quoteTypePriceList"
                          :is-fixed-commission="fixedCommission"
                          :unit-rate-uplift="unitRateUplift"
                          v-on:unit-rate-uplift-changed="unitRateUpliftChanged"
                          :ur-max-uplift="urMaxUplift"
                          :ur-inc-uplift="urIncUplift"
                          :eac-disabled="isInternalEACGuideSelected"
                          :eac-guided-value="eacGuidedValue"
                        ></UnitRateCommissions>
                        <!-- {{ fixedCommission ? resultFCEst : resultEstInput }} -->
                        <TotalSubmitContract
                          @update-number="update"
                          :total-est-input="totalEstInput"
                          :is-fixed-commission-on="fixedCommission"
                          class="mt-3"
                          :total-eac="totalEac"
                          :total-est="totalEst"
                          :relevant-eac="relevantEac"
                        ></TotalSubmitContract>
                        <!-- <TableSubmitContract
                                    :lgValue="lgValue"
                                    :lgDeclared="lgDeclared"
                                    :lgDeclaredValue="lgDeclaredValue"
                                    :lgLive="lgLive"
                                    :lgLiveValue="lgLiveValue"
                                    :clValue="clValue"
                                    :clDeclared="clDeclared"
                                    :clDeclaredValue="clDeclaredValue"
                                    :clLive="clLive"
                                    :clLiveValue="clLiveValue"
                                    ></TableSubmitContract> -->
                      </v-container>
                    </v-row>
                  </v-container>
                  <v-btn class="mr-2" color="primary" @click="e1 = 7">
                    Back
                  </v-btn>
                  <v-btn
                    v-if="
                      csdText && termInMonths && noValueNullInQuoteTypePriceList
                    "
                    class="mr-2"
                    color="accent"
                    :disabled="isSubmitting"
                    @click="submitContract"
                  >
                    Submit
                  </v-btn>
                  <!--                       company !== 'utility hive' ||
                      (meterTypeId !== 2705 &&
                        (eacOptionSelected === 427 ||
                          (eacOptionSelected !== 427 &&
                            eacConfirmedFile !== null &&
                            eacConfirmedFile.length > 0)) &&
                        cedOptionSelected !== 0 &&
                        cedConfirmedFile !== null) ||
                      (meterTypeId === 2705 &&
                        currentRetailerPrice &&
                        soldPrice) -->
                  <v-btn
                    v-if="
                      selectedContractFrom === 3 &&
                      showEFormBtn &&
                      csdText &&
                      termInMonths &&
                      noValueNullInQuoteTypePriceList
                    "
                    color="accent"
                    class="ml-2"
                    :disabled="isSubmitting"
                    @click="openEForm"
                  >
                    REVIEW & SEND CONTRACT
                  </v-btn>
                  <!--   <v-btn
                    color="accent"
                    class="ml-2"
                    :disabled="isSubmitting"
                    @click="openBesForm"
                  >
                    REVIEW & SEND CONTRACT bform
                  </v-btn> -->
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
    <EFormModal
      :eFormmodal="eFormmodal"
      :contractMeterTypesList="contractMeterTypesList"
      @closeEFormModal="closeEFormModal"
    />
    <BesFormModal
      :meter-type-id="meterTypeId"
      :supplier-id="soldSupplierId"
      :meter-id="meterId"
      :besFormmodal="besFormmodal"
      :contractMeterTypesList="contractMeterTypesList"
      :contact-id="selectedSignificantPerson.contactId"
      @closeBesFormModal="closeBesFormModal"
    />
    <ValdaFormModal
      :meter-type-id="meterTypeId"
      :supplier-id="soldSupplierId"
      :meter-id="meterId"
      :valdaFormmodal="valdaFormmodal"
      :contractMeterTypesList="contractMeterTypesList"
      :contact-id="selectedSignificantPerson.contactId"
      @closeBesFormModal="closeValdaFormModal"
    />
  </v-row>
</template>

<script>
import AddressWidget from "@/components/address-widget"

import dayjs from "dayjs"
import { mask } from "vue-the-mask"
// import VCleaveInput from "vuetify-cleave";

import api from "@/store/api"
import SupplyNumber from "@/components/supply-number"
import StandingChargeCommission from "@/components/forms/standing-charge-commission"
import FixedCommission from "@/components/forms/fixed-commission"
import UnitRateCommissions from "@/components/forms/unit-rate-commissions"
import TotalSubmitContract from "@/components/forms/total-submit-contract"
import BaseTelephoneInput from "@/components/common/BaseTelephoneInput"

// import TableSubmitContract from '@/components/forms/table-submit-contract'
import CreditInformation from "@/components/forms/credit-info-sc"
import { mapGetters, mapActions } from "vuex"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import EFormModal from "@/components/dialogs/e-form.vue"
import BesFormModal from "@/components/dialogs/bes-form.vue"
import ValdaFormModal from "@/components/dialogs/valda-form.vue"

export default {
  data: () => ({
    firstTime: false,
    contacatAddressCountError: false,
    dirtOfBirthError: false,
    changedMeterType: false,
    renewalPaymentTypeList: [],
    selectedHomeAddress: "",
    acquisitionReasons: [],
    eFormmodal: false,
    valdaFormmodal: false,
    besFormmodal: false,
    showEFormBtn: false,
    currentRetailerPriceRules: [
      (value) => !!value || "Please Provide Current Retailer Price",
    ],
    soldPriceRules: [(value) => !!value || "Please Provide Sold Price"],
    standardCommission: null,
    standardCommissionRules: [
      (value) => !!value || "Please Provide Uplift Commission",
    ],
    upliftCommission: null,
    upliftCommissionRules: [
      (value) => !!value || "Please Provide Uplift Commission",
    ],
    soldPrice: null,
    currentRetailerPrice: null,
    contactNumbers: [],
    selectedContactContactNumbers: [],
    selectedContactNumber: null,
    selectedContact: null,
    selecedSecurityPassword: "",
    creditSafeLogo: require("@/assets/credit-safe-logo.svg"),
    experianLogo: require("@/assets/experian-logo.svg"),
    selectedCreditMode: null,
    readyForQuoteData: {},
    fpp: false,
    eacGuidedValue: 0,
    company: "",
    isCSDChanged: false,
    isInternalEACGuideSelected: false,
    errorMsg: "",
    errorMsgCC: "",
    dob: null,
    dobText: null,
    menuDOB: false,
    shouldApplyRegNoValidation: false,
    isSubmitting: false,
    UHClasses: "",
    CSSClasses: "",
    showCampaignError: false,
    showCreditSafeError: false,

    companyNameRules: [(value) => !!value || "Please Provide Company Name"],
    // contactNumberRules: [
    //     value => !!(value.length !== 11) || 'Please provide a valid Contact Number',
    //     // value => !!(value.length < 11) || 'Please provide a valid Contact Number'
    // ],

    natureOfBusinessIdRules: [
      (value) => !!value || "Please Provide Nature of Business",
    ],
    tradingTypeIdRules: [(value) => !!value || "Please Provide Trading Type"],
    // registrationNoRules: [
    //     value => !!value || 'Please Provide Correct Registration Number'
    // ],
    // creditScoreRules: [
    //   (value) => !!value || "Please Provide Credit Score Between 1 and 100",
    // ],
    // experianScoreRules: [
    //   (value) => !!value  || "Please Provide Experian Score Between 1 and 1000",
    // ],
    creditSafeCompanyNameRules: [
      (value) => !!value || "Please Provide Credit Safe Company Name",
    ],
    experianCompanyNameRules: [
      (value) => !!value || "Please Provide Experian Company Name",
    ],
    creditLimitRules: [
      (value) => !!value || value === 0 || "Please Provide Credit Limit",
    ],
    creditSafeIDRules: [(value) => !!value || "Please Provide Credit Safe ID"],
    sortCodeRules: [
      // (value) => {
      //   if(this.payment.typeId === 1 || this.payment.typeId === "1") {
      //     if (value?.length > 5) return true
      //     return "Sort Code must be 6 digits"
      //   }

      //   return !!value || "Please Provide Sort Code"
      // }
      (value) => {
        if (value?.length > 5) return true
        return "Sort code must be at least 6 digits."
      },
    ],
    bankNameRules: [(value) => !!value || "Please Provide Bank Name"],
    acountNumberRules: [
      (value) => {
        if (value?.length > 7) return true
        return "Account Number must be at least 8 digits."
      },
    ],
    accountNameRules: [(value) => !!value || "Please Provide Account Name"],
    significantPersonContactIdRules: [
      (value) => !!value || "Please Select a Significant Person",
    ],
    selectedContractFromRules: [
      (value) => !!value || "Please Select Contract Form",
    ],
    selectedAgreedByRules: [(value) => !!value || "Please Select Agreed By"],
    selecedSecurityPasswordRules: [
      (value) => {
        if (value?.length > 5) return true
        return "Password must be at least 6 digits."
      },
    ],
    soldSupplierIdRules: [(value) => !!value || "Please Select a Supplier"],
    selectedMeterTypeRules: [
      (value) => !!value || "Please Select a Meter Type",
    ],
    selectedTermRules: [(value) => !!value || "Please Select a Term"],
    csdRules: [(value) => !!value || "Please Select a Contract Start Date"],
    errorMessages: "",

    formHasErrors: false,
    selectedMeterType: null,
    payment: {
      accountName: null,
      accountNumber: null,
      addressId: null,
      bankName: null,
      id: 0,
      sortCode: null,
      type: null,
      typeId: null,
    },
    csd: null,
    csdText: null,
    formattedDate: {
      date: true,
      datePattern: ["d", "m", "Y"],
      delimiter: "/",
    },
    creditScore: null,
    creditSafeCompanyName: null,
    creditLimit: null,
    experianScore: null,
    experianCompanyName: null,
    experianLimit: null,
    termInMonths: 0,
    selectedAgreedBy: null,
    selectedAddressId: null,
    selectedContractFrom: null,
    contractType: undefined,
    contractTypeValidationError: false,
    significantPersonContactId: null,
    selectedCampaign: 2,
    showPostcodes: false,
    dialog: false,
    isDobTextChanged: false,
    e6: 1,
    e1: 1,
    form: {
      dateOfBirth: "",
      dateFrom: "",
      dateTo: "",
      contractEndDate: "",
    },
    soldSupplierId: null,
    selectedMeter: {},
    // significantPersonsList: [],
    selectedSignificantPerson: {
      companyId: null,
      contactNumber: null,
      altContactNumber: null,
    },
    dbSelectedSignificantPerson: {},
    contractMeterTypesList: [],
    TermTypesList: [],
    profileClass: null,

    suppliers: [],
    campaigns: [
      { value: 1, text: "Commercial Support Service" },
      { value: 2, text: "Utility Hive" },
    ],
    unitRateUplift: 0,
    standingChargeUplift: 0,
    fixedCommission: false,
    scMaxUplift: 0,
    scIncUplift: 0,
    urMaxUplift: 0,
    urIncUplift: 0,
    window: 0,
    lgDeclaredComms: 0,
    lgLiveComms: 0,
    clDeclaredComms: 0,
    clLiveComms: 0,
    fixedComm: 0,
    quoteTypePriceList: [],
    totalEstInput: 0,
    eacOptions: [],
    cedOptions: [],
    eacOptionSelected: 0,
    cedOptionSelected: 0,
    eacConfirmedFile: null,
    cedConfirmedFile: null,
    alreadyExistingNumber: null,
    acquisitionReasonId: null,
  }),
  directives: { mask },
  props: {
    meterId: Number,
    addressId: {
      type: Number,
    },

    meterTypeId: Number,
    supplierName: String,
    supplierId: Number,
    pc: String,
    contractEndDate: Number,
  },
  components: {
    ValdaFormModal,
    BesFormModal,
    SupplyNumber,
    AddressWidget,
    StandingChargeCommission,
    UnitRateCommissions,
    TotalSubmitContract,
    CreditInformation,
    FixedCommission,
    BaseTelephoneInput,
    EFormModal,

    // VCleaveInput,
  },
  computed: {
    ...mapGetters("contacts", ["contactAddresses"]),
    ...mapGetters("company", ["details", "companyId", "comapny"]),
    ...mapGetters("employee", [
      "employeeDetails",
      "leadGeneratorDetails",
      "userId",
      "userName",
      "employeeCampaign",
    ]),
    ...mapGetters("meters", ["meters"]),
    ...mapGetters("contacts", ["contacts"]),
    ...mapGetters("addresses", ["selectedAddress", "paymentInfo"]),
    ...mapGetters("forms", [
      "natureOfBusinessList",
      "tradingTypeList",
      "titleList",
      "supplierList",
      "paymentTypesList",
      "contractTypesList",
    ]),
    savings() {
      if (!this.currentRetailerPrice || !this.soldPrice) {
        return ""
      }
      return parseFloat(
        parseFloat(this.currentRetailerPrice) - parseFloat(this.soldPrice)
      ).toFixed(2)
    },
    creditScoreRules() {
      const min = 1
      const max = 100
      return [
        (v) =>
          (v >= min && v <= max) ||
          "Credit Score must be between " + min + " and " + max,
      ]
    },
    experianScoreRules() {
      const min = 1
      const max = 1000
      return [
        (v) =>
          (v >= min && v <= max) ||
          "Experian Score must be between " + min + " and " + max,
      ]
    },
    paymentList() {
      if (this.contractType === 0) {
        return this.paymentTypesList
      } else {
        return this.renewalPaymentTypeList
      }
    },
    noValueNullInQuoteTypePriceList() {
      let isAnyBaseRateNull = false

      if (this.quoteTypePriceList && this.quoteTypePriceList.length > 0) {
        this.quoteTypePriceList.forEach((item) => {
          if (item.baseRate === null || item.baseRate === "") {
            isAnyBaseRateNull = true
          }
        })
      }
      return !isAnyBaseRateNull
    },

    savingsPercentage() {
      if (
        !this.currentRetailerPrice ||
        !this.soldPrice ||
        this.currentRetailerPrice === "" ||
        !this.soldPrice === "" ||
        this.currentRetailerPrice === 0 ||
        !this.soldPrice === 0
      ) {
        return ""
      }
      return parseFloat(
        parseFloat(this.savings / this.currentRetailerPrice) * 100
      ).toFixed(2)
    },

    savingsValue() {
      if (
        parseFloat(this.currentRetailerPrice) === parseFloat(this.soldPrice)
      ) {
        return "0 (0%)"
      }
      if (!this.currentRetailerPrice || !this.soldPrice) {
        return "0 (0%)"
      }
      return (
        `${this.savings ? this.savings : ""}` +
        `${this.savingsPercentage ? " (" + this.savingsPercentage + "%)" : ""}`
      )
    },

    waterTotalEstContractValue() {
      if (!this.upliftCommission || !this.standardCommission) {
        return 0
      }
      return parseFloat(
        parseFloat(this.upliftCommission) + parseFloat(this.standardCommission)
      )
    },

    contactNumbList() {
      let number = null
      if (this.contractTypesList) {
        number = this.contacts
          .filter((x) => {
            return x.contactId === this.selectedAgreedBy
          })
          .map((contact) => ({
            value: contact.contactNumber,
            text: contact.contactNumber,
          }))
        return number
      }

      return number
    },

    significantPersonsList() {
      let personList = this.contacts
      if (this.contacts) {
        personList = this.contacts.map((contact) => ({
          value: contact.contactId,
          text: contact.firstName + " " + contact.lastName,
        }))
      }
      return personList
    },

    companyColor() {
      if (!this.company) return "grey"
      return this.company === "utility hive"
        ? "accent"
        : this.company === "sub broker"
        ? "sub-broker-color"
        : "green"
    },
    resultEstInput() {
      const total = this.totalEst
      return total
    },
    resultFCEst() {
      const total = +this.totalEstInput
      return total
    },
    dobTextRules() {
      return [
        (v) => {
          const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
          const dobText = v ? v.split("/").reverse().join("-") : ""
          const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
          const diff = dayjs(currentDOB).diff(maxDate, "day")
          let validate = true
          if (diff >= 1 && v.length >= 10) {
            validate = false
          }

          return !!validate || "Must be at least 18 years old"
        },
      ]
    },
    registrationNoRules() {
      return [
        (value) => !!value || "Please Confirm Company Registration Number",
      ]
    },
    formattedMinContractEndDate() {
      let minDate =
        dayjs(this.contractEndDate * 1000) <= dayjs()
          ? dayjs()
          : dayjs(this.contractEndDate * 1000).add(1, "day")

      if (this.contractType === 2) {
        const minContractEndDate = dayjs().add(1, "day")

        return minContractEndDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
      }

      return minDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
    },
    formattedMaxContractEndDate() {
      const isINPastAndWindowNull =
        dayjs(this.contractEndDate * 1000) <= dayjs() && !this.window
      const maxDate = dayjs().add(this.window + 1, "days")

      if (this.contractType === 2) {
        const maxContractEndDate = dayjs().add(365, "year")

        return maxContractEndDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
      }

      return isINPastAndWindowNull
        ? null
        : maxDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
    },
    formattedMaxDateOfBirth() {
      const maxDate = dayjs()
        .subtract(18, "year")
        .add(1, "day")
        .format("YYYY-MM-DD")
      return dayjs(maxDate).toISOString()
    },

    termInMonthsRules() {
      return [
        (value) => !!value || "Please provide the term",
        (value) => !!value <= 60 || "Term must not be greater than 60",
        // value => !!((value * 30) <= this.window) || `Term must be less than ${this.window} days`
      ]
    },
    eacOptionsRules() {
      return [(value) => !!value || "Please Select an Option"]
    },
    cedOptionsRules() {
      return [(value) => !!value || "Please Select an Option"]
    },
    acquisitionReasonRules() {
      return [(value) => !!value || "Please Select an Option"]
    },
    // contactNumberRules() {
    //     return [
    //         value => !!(value.length !== 11) || 'Please provide a valid Contact Number',
    //         // value => !!(value.length < 11) || 'Please provide a valid Contact Number'
    //     ]

    // },

    masterContractType() {
      return this.contractType + 1
    },
    renewSupplierList() {
      const supplierList = []
      if (this.suppliers.findIndex((x) => x.value === this.supplierId) > -1) {
        supplierList.push({ text: this.supplierName, value: this.supplierId })
      }
      return supplierList
    },
    contractTypeAndSelectedCampaign() {
      const selectedCampaign = this.company === "utility hive" ? 2 : 1
      return `${this.contractType}|${selectedCampaign}`
    },
    ced() {
      if (this.csd === null || !this.isCSDChanged) {
        return dayjs(this.contractEndDate * 1000).format("DD/MM/YYYY")
      }
      const ced = dayjs(this.csd)
        .add(this.termInMonths, "months")
        .add(-1, "day")
        .format("DD/MM/YYYY")
      // const ced = dayjs(this.csd).add(this.termInMonths, 'months').add(-1, 'days').format('DD/MM/YYYY')
      if (ced === "Invalid Date") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.contractEndDate = ""
        return ""
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.contractEndDate = dayjs(this.csd)
          .add(this.termInMonths, "months")
          .add(-1, "day")
          .format("YYYY-MM-DD")
        return ced
      }
    },
    quoteTypesOnly() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId !== 1)
    },
    totalEac() {
      var result = 0
      var self = this
      self.quoteTypesOnly.forEach(function (q) {
        var index = self.quoteTypePriceList.findIndex(
          (x) => x.quoteTypePriceId === q.quoteTypePriceId
        )
        result =
          result +
          parseFloat(
            self.quoteTypePriceList[index].eac == ""
              ? 0
              : self.quoteTypePriceList[index].eac
          )
      })
      return parseFloat(result.toFixed(2))
    },
    relevantEac() {
      const totalRelevantEac =
        (this.totalEac / 12) *
        parseInt(this.termInMonths ? this.termInMonths : 0)
      return parseFloat(totalRelevantEac.toFixed(2))
    },
    totalEst() {
      /*
                unit rate, standing charge, contract term, relevant eac
                total unit rate = unit rate x relevant eac
                term years = contract term / 12
                total standing charge = standing charge x 365 x term years

                total = total unit rate + total standing charge
            */

      var totalUnitRate = parseFloat(this.unitRateUplift) * this.relevantEac
      var termYears = parseInt(this.termInMonths) / 12
      var totalSC = parseFloat(this.standingChargeUplift) * 365 * termYears
      let total = 0

      if (totalUnitRate > 0 || totalSC > 0) {
        total = (totalUnitRate + totalSC) / 100
      }

      return parseFloat(total.toFixed(2))
    },
    lgValue() {
      return 0
    },
    lgDeclared() {
      return 0
    },
    lgDeclaredValue() {
      return 0
    },
    lgLive() {
      return 0
    },
    lgLiveValue() {
      return 0
    },
    clValue() {
      return this.lgValue
    },
    clDeclared() {
      return 0
    },
    clDeclaredValue() {
      return 0
    },
    clLive() {
      return 0
    },
    clLiveValue() {
      return 0
    },
  },
  watch: {
    paymentInfo: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.payment.accountName = this.paymentInfo.accountName
          this.payment.accountNumber = this.paymentInfo.accountNumber
          this.payment.addressId = this.paymentInfo.addressId
          this.payment.bankName = this.paymentInfo.bankName
          this.payment.id = this.paymentInfo.id
          this.payment.sortCode = this.paymentInfo.sortCode
          this.payment.type = this.paymentInfo.type
          this.payment.typeId = this.paymentInfo.typeId
        }
      },
      deep: true,
    },

    selectedSignificantPerson: {
      handler(newVal) {
        if (newVal.contactNumber !== this.alreadyExistingNumber) {
          this.errorMsgCC = ""
        }
      },
      deep: true,
    },
    selectedContactContactNumbers: {
      handler(newVal) {
        console.log(newVal)
        if (newVal && newVal.length >= 1) {
          this.selectedContactNumber = newVal[0]
          console.log(this.selectedContactNumber, "contactnum")
        }
      },
      deep: true,
    },
    contractTypeAndSelectedCampaign(newVal) {
      const [contractType, selectedCampaign] = newVal.split("|")
      if (contractType !== "null" && selectedCampaign !== "null") {
        this.fetchContractInfoSuppliers()
      }
    },
    termInMonths(newVal) {
      if (parseInt(newVal, 10) > 60) {
        this.termInMonths = "60"
      }
    },
    contractType(newVal, oldValue) {
      if (newVal !== oldValue) {
        // this.noValueNullInQuoteTypePriceList();
      }
    },
  },
  mounted() {
    if (this.details.dataSourceId === 2725) {
      this.company = "sub broker"
      this.fetchContractInfoSuppliers()
    }
    this.fetchReadyForQuote()
    this.fetchPaymentInfo(this.addressId)
    this.selectAddress(this.addressId)

    const minDate =
      dayjs(this.contractEndDate * 1000) <= dayjs()
        ? dayjs().add(1, "day").format("YYYY-MM-DD")
        : dayjs(this.contractEndDate * 1000)
            .add(1, "day")
            .format("YYYY-MM-DD")
    this.csd = minDate

    this.fetchContractMeterTypes()
    if (this.meters) {
      const filterMeter = this.meters.filter(
        (meter) => meter.meterId === this.meterId
      )
      this.selectedMeter = filterMeter[0]
      console.log(this.selectedMeter, "selcted meter contract")
    }

    // if (this.contacts) {
    //   this.significantPersonsList = this.contacts.map(contact => ({
    //     value: contact.contactId,
    //     text: contact.firstName + " " + contact.lastName
    //   }))
    // }
    this.creditSafeCompanyName = this.details.creditSafeCompanyName
    this.creditScore = this.details.creditSafeScore
    this.creditLimit = this.details.creditSafeLimit

    this.experianCompanyName =
      this.details &&
      this.details.experianCompanyName &&
      this.details.experianCompanyName.length > 0
        ? this.details.experianCompanyName.trim()
        : this.details.experianCompanyName
    this.experianScore = this.details.experianScore
    this.experianLimit = this.details.experianLimit

    if (this.paymentInfo) {
      this.payment.accountName = this.paymentInfo.accountName
      this.payment.accountNumber = this.paymentInfo.accountNumber
      this.payment.addressId = this.paymentInfo.addressId
      this.payment.bankName = this.paymentInfo.bankName
      this.payment.id = this.paymentInfo.id
      this.payment.sortCode = this.paymentInfo.sortCode
      this.payment.type = this.paymentInfo.type
      this.payment.typeId = this.paymentInfo.typeId
    } else {
      this.payment.addressId = this.addressId
    }
    this.fetchAcquisitionReasons()
    this.fetchEacObtained()
    this.fetchCedObtained()
  },
  methods: {
    ...mapActions("employee", ["fetchLeadGenerator"]),
    ...mapActions("meters", ["fetchMetersAfterRemove"]),
    ...mapActions("contacts", ["fetchContacts"]),
    ...mapActions("company", ["fetchCompanyDetails"]),
    ...mapActions("addresses", ["fetchPaymentInfo", "selectAddress"]),
    ...mapActions("ui", ["openForm"]),
    ...mapActions("forms", ["postEFormDetails", "fetchBESNatureOfBusiness"]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("contacts", ["fetchContactAddresses"]),
    handlePaste(event) {
      let pasteContent = (event.clipboardData || window.clipboardData).getData(
        "text"
      )
      pasteContent = pasteContent.replace(/\s/g, "") // Remove spaces from pasted content

      if (!/^\d+$/.test(pasteContent)) {
        event.preventDefault()
      } else {
        // Set the cleaned content to the input
        event.preventDefault()
        this.payment.sortCode = pasteContent
      }
    },
    handlePasteAccountNumber(event) {
      let pasteContent = (event.clipboardData || window.clipboardData).getData(
        "text"
      )
      pasteContent = pasteContent.replace(/\s/g, "") // Remove spaces from pasted content

      if (!/^\d+$/.test(pasteContent)) {
        event.preventDefault()
      } else {
        // Set the cleaned content to the input
        event.preventDefault()
        this.payment.accountNumber = pasteContent
      }
    },
    editAddressClicked(id) {
      this.selectedAddressId = id
    },
    closeEFormModal() {
      this.eFormmodal = false
    },
    closeBesFormModal() {
      this.besFormmodal = false
    },
    closeValdaFormModal() {
      this.valdaFormmodal = false
    },
    openBesForm() {
      const contractEndDate = this.ced
      const tmpContractEndDate = contractEndDate.split("/").reverse().join("-")
      const currentCED = dayjs(tmpContractEndDate).format("YYYY-MM-DD")

      let finalContractEndDate = currentCED
      if (finalContractEndDate === "Invalid Date") {
        finalContractEndDate = ""
      }

      const selectedCampaign =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      const isFixedCommission = this.fixedCommission
        ? this.resultFCEst
        : this.resultEstInput
      const contractType =
        this.masterContractType === 1
          ? "Renewal"
          : this.masterContractType === 2
          ? "Acquisition"
          : "New Mover"
      const contractIcon =
        this.masterContractType === 1
          ? "fas fa-undo"
          : this.masterContractType === 2
          ? "fas fa-arrow-right"
          : "fas fa-truck"

      const contractData = {
        meterID: this.meterId,
        companyID: this.details.companyId,
        meterTypeID: this.meterTypeId,
        currentSupplierID: this.supplierId,
        saleType: selectedCampaign,
        masterContractType: this.masterContractType,
        paymentID: this.paymentInfo.id,
        contractTypeID: this.selectedContractFrom,
        selectedSupplierMeterTypeID: this.selectedMeterType,
        addressId: this.addressId,
        csd: dayjs(this.csd).unix(),
        term: parseInt(this.termInMonths),
        ced: dayjs(finalContractEndDate).unix(),
        closerId: this.employeeDetails.employeeId,
        significantId: this.significantPersonContactId,
        soldSupplierId: this.soldSupplierId,
        agreedContractId: this.selectedAgreedBy,
        lgDeclaredComms: this.lgDeclaredComms,
        lgLiveComms: this.lgLiveComms,
        clDeclaredComms: this.clDeclaredComms,
        clLiveComms: this.clLiveComms,
        clDeclCLLiveCommsaredComms: 0,
        processedContractValue: isFixedCommission,
        declaredContractValue: this.lgDeclaredValue,
        userId: this.userId,
        quoteTypePriceList: this.quoteTypePriceList,
        isFixedComm: this.fixedCommission,
        eacObtainedId: this.eacOptionSelected,
        cedObtainedId: this.cedOptionSelected,
        acquisitionReasonId:
          this.acquisitionReasonId !== null ? this.acquisitionReasonId : 0,
        contractType: contractType,
        contractTypeIcon: contractIcon,
        fpp:
          this.company === "utility hive" &&
          this.masterContractType === 2 &&
          this.meterTypeId !== 2705
            ? this.fpp
            : false,
        currentRetailerPrice:
          this.meterTypeId === 2705 ? this.currentRetailerPrice : 0,
        paymentTypeId: this.payment.typeId,
        soldPrice: this.meterTypeId === 2705 ? this.soldPrice : 0,
        savingValue: this.meterTypeId === 2705 ? this.savings : 0,
        savingPercent: this.meterTypeId === 2705 ? this.savingsPercentage : 0,
        upliftCommission: this.meterTypeId === 2705 ? this.upliftCommission : 0,
        standardCommission:
          this.meterTypeId === 2705 ? this.standardCommission : 0,
        totalEstContractValue:
          this.meterTypeId === 2705 ? this.waterTotalEstContractValue : 0,
      }

      this.postEFormDetails({
        ...contractData,
        ...this.selectedAddress,
        ...this.details,
        ...this.selectedSignificantPerson,
      })

      this.besFormmodal = true
    },

    openEForm() {
      const contractEndDate = this.ced
      const tmpContractEndDate = contractEndDate.split("/").reverse().join("-")
      const currentCED = dayjs(tmpContractEndDate).format("YYYY-MM-DD")

      let finalContractEndDate = currentCED
      if (finalContractEndDate === "Invalid Date") {
        finalContractEndDate = ""
      }

      const selectedCampaign =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      const isFixedCommission = this.fixedCommission
        ? this.resultFCEst
        : this.resultEstInput
      const contractType =
        this.masterContractType === 1
          ? "Renewal"
          : this.masterContractType === 2
          ? "Acquisition"
          : "New Mover"
      const contractIcon =
        this.masterContractType === 1
          ? "fas fa-undo"
          : this.masterContractType === 2
          ? "fas fa-arrow-right"
          : "fas fa-truck"

      const filteredPayment = this.paymentTypesList.filter(
        (paymentType) => paymentType.value === this.payment.typeId
      )

      const contractData = {
        meterID: this.meterId,
        companyID: this.details.companyId,
        meterTypeID: this.meterTypeId,
        currentSupplierID: this.supplierId,
        saleType: selectedCampaign,
        masterContractType: this.masterContractType,
        paymentID: this.paymentInfo.id,
        contractTypeID: this.selectedContractFrom,
        selectedSupplierMeterTypeID: this.selectedMeterType,
        addressId: this.addressId,
        csd: dayjs(this.csd).unix(),
        term: parseInt(this.termInMonths),
        ced: dayjs(finalContractEndDate).unix(),
        closerId: this.employeeDetails.employeeId,
        significantId: this.significantPersonContactId,
        soldSupplierId: this.soldSupplierId,
        agreedContractId: this.selectedAgreedBy,
        lgDeclaredComms: this.lgDeclaredComms,
        lgLiveComms: this.lgLiveComms,
        clDeclaredComms: this.clDeclaredComms,
        clLiveComms: this.clLiveComms,
        clDeclCLLiveCommsaredComms: 0,
        processedContractValue: isFixedCommission,
        declaredContractValue: this.lgDeclaredValue,
        userId: this.userId,
        quoteTypePriceList: this.quoteTypePriceList,
        isFixedComm: this.fixedCommission,
        eacObtainedId: this.eacOptionSelected,
        cedObtainedId: this.cedOptionSelected,
        acquisitionReasonId:
          this.acquisitionReasonId !== null ? this.acquisitionReasonId : 0,
        contractType: contractType,
        contractTypeIcon: contractIcon,
        fpp:
          this.company === "utility hive" &&
          this.masterContractType === 2 &&
          this.meterTypeId !== 2705
            ? this.fpp
            : false,
        currentRetailerPrice:
          this.meterTypeId === 2705 ? this.currentRetailerPrice : 0,
        soldPrice: this.meterTypeId === 2705 ? this.soldPrice : 0,
        savingValue: this.meterTypeId === 2705 ? this.savings : 0,
        savingPercent: this.meterTypeId === 2705 ? this.savingsPercentage : 0,
        upliftCommission: this.meterTypeId === 2705 ? this.upliftCommission : 0,
        standardCommission:
          this.meterTypeId === 2705 ? this.standardCommission : 0,
        totalEstContractValue:
          this.meterTypeId === 2705 ? this.waterTotalEstContractValue : 0,
        billingAddress:
          this.selectedAddress.siteName +
          " " +
          this.selectedAddress.add1 +
          " " +
          this.selectedAddress.add2 +
          " " +
          this.selectedAddress.town +
          " " +
          this.selectedAddress.county +
          " " +
          this.selectedAddress.postcode,
        supplyAddress:
          this.selectedAddress.siteName +
          " " +
          this.selectedAddress.add1 +
          " " +
          this.selectedAddress.add2 +
          " " +
          this.selectedAddress.town +
          " " +
          this.selectedAddress.county +
          " " +
          this.selectedAddress.postcode,
        dobText: this.dobText,
        dob: this.dob,
        selectedAddressId: this.selectedAddressId,
        paymentTypeId: this.payment.typeId,
        bankName: this.payment.bankName,
        sortCode: this.payment.sortCode,
        accountName: this.payment.accountName,
        accountNumber: this.payment.accountNumber,
        paymentType: filteredPayment[0].text,
        csdText: this.csdText,
        cedText: this.ced,
        totalEac: this.totalEac,
        selectedAgreedByContact: this.contacts.find((x) => {
          return x.contactId === this.selectedAgreedBy
        }),
        upfrontPaymentAmount: null,
        greenEnergy: null,
        besBusinessType: null,
      }

      this.postEFormDetails({
        ...contractData,
        ...this.selectedAddress,
        ...this.details,
        ...this.selectedSignificantPerson,
      })
      if (this.soldSupplierId === 13) {
        this.besFormmodal = true
      } else if (this.soldSupplierId === 112) {
        this.valdaFormmodal = true
      } else {
        setTimeout(() => {
          this.eFormmodal = true
        }, 2000)
      }
    },

    async sendSMS() {
      const contactId = this.selectedContact[0].contactId
      const phoneNumber = this.selectedContactNumber
      const noteMessage =
        this.company == "utility hive"
          ? "Password SMS Sent from Utility Hive"
          : this.company == "sub broker"
          ? "Password SMS Sent from Sub Broker Portal"
          : "Password SMS Sent from Commercial Support Service"

      const saleTypeId =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      const details = {
        contactId: contactId,
        companyId: this.companyId,
        phoneNumber: phoneNumber,
        noteMessage: noteMessage,
        currentUserId: this.userId,
        saleTypeId: saleTypeId,
        passwordOveride: this.securityPassword,
      }

      const response = await api.post(`sendAgreedByPasswordSMS`, details)
      if (response && response.status === 200) {
        changesSaved(`Password Sent to ${this.selectedContactNumber}`)
      } else {
        somethingWentWrong()
      }
    },

    getContactPassword() {
      let password = null
      if (this.selectedAgreedBy !== null) {
        this.setSelectedContact()
        console.log("this.selectedAgreedBy", this.selectedAgreedBy)
        password = this.contacts
          .filter((x) => {
            return x.contactId === this.selectedAgreedBy
          })
          .map((x) => {
            return x.securityPassword
          })
        this.selecedSecurityPassword = password.toString()
        // return password.toString()
      } else {
        console.log("selecedSecurityPassword selecedSecurityPassword")
        this.selecedSecurityPassword = ""
      }
    },

    setSelectedContact() {
      this.selectedContact = this.contacts.filter((x) => {
        return x.contactId === this.selectedAgreedBy
      })

      // setcontactnumberlist
      this.contactNumbers.push(this.selectedContact[0].contactNumber)
      // this.contactNumbers.push(this.contact.altNumber)
      this.contactNumbers.push(this.details.telephone)
      this.contactNumbers.forEach((phone) => {
        const validPhone = phone && phone.startsWith("07")
        if (validPhone) {
          this.selectedContactContactNumbers.push(phone)
        }
      })
    },

    supplierLogo(id) {
      return `https://crm.utilityhive.com/Img/SupplierLogos/${id}.jpg`
    },

    async fetchReadyForQuote() {
      const response = await api.get(`readyForQuote/${this.companyId}`)
      if (response && response.status === 200) {
        this.readyForQuoteData = response.data
      }
    },

    experianLogoSelected(creditMode) {
      this.selectedCreditMode = creditMode
      this.showCreditSafeError = false
      this.fetchContractInfoSuppliers()
    },

    submitContractOpened() {
      this.fetchLeadGenerator(this.companyId)
      this.fetchPaymentInfo(this.addressId)
      this.selectAddress(this.addressId)

      this.fetchBESNatureOfBusiness(this.meterTypeId)
    },
    update(number) {
      this.totalEstInput = +number
    },

    formatName() {
      const cameltext =
        this.details.companyName.charAt(0).toUpperCase() +
        this.details.companyName
          .slice(1)
          .replace(/\s(.)/g, function (a) {
            return a.toUpperCase()
          })
          .replace(/^(.)/, function (b) {
            return b.toLowerCase()
          })

      this.details.companyName = cameltext
    },

    async downloadDocument() {
      await api
        .get(`downloadCedObtainedProofFile/${this.companyId}`)
        .then((res) => {
          if (res.status === 200) {
            const apiUrl = process.env.VUE_APP_BASE_URL
            window.location = `${apiUrl}/downloadCedObtainedProofFile/${this.companyId}`
          }
        })
        .catch((err) => {
          console.log(err)
          somethingWentWrong("Sorry! Document for this sale doesn't Exist")
        })
    },

    UHCampaignClicked() {
      this.eacGuidedValue = 0
      this.isInternalEACGuideSelected = false
      this.company = "utility hive"
      this.UHClasses = "v-item--active v-btn--active"
      this.CSSClasses = ""
      if (this.eacOptionSelected === 427) {
        this.getEACGuide()
      }
      this.fetchContractInfoSuppliers()
    },
    CSSCampaignClicked() {
      this.company = "commercial support service"
      this.CSSClasses = "v-item--active v-btn--active"
      this.UHClasses = ""
      if (
        !this.fixedComm ||
        this.fixedComm === 0 ||
        (this.fixedComm === 1 && !this.fixedCommission)
      ) {
        this.getEACGuide()
      }
      this.fetchContractInfoSuppliers()
    },
    jobTitleCapitalLetter() {
      this.selectedSignificantPerson.jobTitle =
        this.selectedSignificantPerson.jobTitle
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
    },
    lastNameCapitalLetter() {
      this.selectedSignificantPerson.lastName =
        this.selectedSignificantPerson.lastName
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
    },
    firstNameCapitalLetter() {
      this.selectedSignificantPerson.firstName =
        this.selectedSignificantPerson.firstName
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
    },
    NoLettersAccepted(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (this.form.sortCode && this.form.sortCode.length >= 6) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    unitRateUpliftChanged(value) {
      this.unitRateUplift = value
    },
    standingChargeUpliftChanged(value) {
      this.standingChargeUplift = value
    },
    fixedCommissionChanged() {
      this.unitRateUplift = 0
      this.standingChargeUplift = 0
      if (!this.fixedComm || (this.fixedComm === 1 && !this.fixedCommission)) {
        this.getEACGuide()
      }
    },
    dateOfBirthChanged() {
      this.dobText = dayjs(this.dob).format("DD/MM/YYYY")
      // const vCleaveBirthDateElements= document.querySelectorAll('#vCleaveBirthDate')
      // vCleaveBirthDateElements[1].value = dayjs(this.dob).format("DD/MM/YYYY")
    },
    dobTextChanged() {
      this.isDobTextChanged = true
      if (this.dobText.length >= 1) {
        const tempDay = this.dobText.slice(0, 2)
        const tempMonth = this.dobText.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.dobText.slice(2, 10)
          this.dobText = "31" + temp
        }
        if (this.dobText.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.dobText.slice(2, 10)
          this.dobText = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.dobText.slice(5, 10)
          this.dobText = tempDay + "/12" + temp
        }
        if (this.dobText.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.dobText.slice(5, 10)
          this.dobText = tempDay + "/01" + temp
        }
        const dobText = this.dobText.split("/").reverse().join("-")
        // const maxDate = dayjs().subtract(18, 'year').format('YYYY-MM-DD')
        const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
        // const diff = dayjs(currentDOB).diff(maxDate, 'day')
        // if(diff >= 1 && this.dobText.length >= 10) {
        //     this.dobText = dayjs().subtract(18, 'year').subtract(1, 'day').format('DD/MM/YYYY')
        //     this.dob = dayjs().subtract(18, 'year').subtract(1, 'day').format('YYYY-MM-DD')
        // }else {

        this.dob = currentDOB
        if (this.dob === "Invalid Date") {
          this.dob = ""
        }
        // }
      }
    },
    dateOfCSDChanged() {
      this.isCSDChanged = true
      this.csdText = dayjs(this.csd).format("DD/MM/YYYY")
    },
    tradingTypeChanged() {
      if (
        this.details.tradingTypeId === 6 ||
        this.details.tradingTypeId === 8
      ) {
        this.shouldApplyRegNoValidation = false
      } else {
        this.shouldApplyRegNoValidation = true
      }
    },

    async getSupplierUplift() {
      const selectedCampaign =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      if (this.soldSupplierId !== null) {
        const res = await api.get(
          `supplierUplift/${this.soldSupplierId}/${this.masterContractType}/${selectedCampaign}/${this.meterTypeId}`
        )
        if (res && res.status === 200) {
          this.scMaxUplift = res.data.scMaxUplift
          this.scIncUplift = res.data.scIncUplift
          this.urMaxUplift = res.data.urMaxUplift
          this.urIncUplift = res.data.urIncUplift
          if (this.urIncUplift === 0) {
            this.unitRateUplift = 0
          }
          this.window = res.data.window
          this.lgDeclaredComms = res.data.lgDeclaredComms
          this.lgLiveComms = res.data.lgLiveComms
          this.clDeclaredComms = res.data.clDeclaredComms
          this.clLiveComms = res.data.clLiveComms
          this.fixedComm = res.data.fixedComm
          if (this.fixedComm) {
            this.fixedCommission = true
          }
          this.getQuoteTypeIds()
        }
      }
    },
    async getQuoteTypeIds() {
      if (this.selectedMeterType !== null) {
        const res = await api.get(`quoteTypePriceIds/${this.selectedMeterType}`)
        if (res && res.status === 200) {
          this.quoteTypePriceList = res.data.sort((a, b) => a.sort - b.sort)
          if (this.eacOptionSelected === 427) {
            this.getEACGuide()
          }
          if (
            !this.fixedComm ||
            this.fixedComm === 0 ||
            (this.fixedComm === 1 && !this.fixedCommission)
          ) {
            this.getEACGuide()
          }
        }
      }
    },
    selectedAddressIdChanged(value) {
      this.selectedAddressId = value
    },
    selectedHomeAddressChanged(value) {
      this.selectedHomeAddress = value
    },

    isNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        event.returnValue = true
        return true
      }
    },

    async updateCompanyAndContinue() {
      if (
        this.details.tradingTypeId === 6 ||
        this.details.tradingTypeId === 8
      ) {
        this.shouldApplyRegNoValidation = false
      } else {
        this.shouldApplyRegNoValidation = true
      }
      this.contractTypeValidationError =
        this.contractType === null ? true : false
      if (
        this.contractTypeValidationError ||
        this.details.companyName === "" ||
        this.details.natureOfBusinessId === "" ||
        (this.shouldApplyRegNoValidation &&
          this.details.registrationNo === "") ||
        this.details.tradingTypeId === ""
      ) {
        const form = {
          companyName: this.details.companyName,
          natureOfBusinessId: this.details.natureOfBusinessId,
          registrationNo: this.details.registrationNo,
          tradingTypeId: this.details.tradingTypeId,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })

        return false
      }
      if (this.company === "" || !this.company) {
        this.showCampaignError = true
        return false
      }
      const companyData = {
        companyId: this.details.companyId,
        companyName: this.details.companyName,
        telephone: this.details.telephone,
        natureOfBusinessId: this.details.natureOfBusinessId,
        tradingTypeId: this.details.tradingTypeId,
        registrationNo: this.details.registrationNo
          ? this.details.registrationNo
          : "",
        website: this.details.website,
        creditSafeCompanyName: this.details.creditSafeCompanyName,
        creditSafeScore: this.details.creditSafeScore,
        creditSafeLimit: this.details.creditSafeLimit,
        creditScoreDate: this.details.creditScoreDate,
        creditSafeId: this.details.creditSafeID,
        creditSafeEmployeeId: "", // TODO: Need safe employee Id
        dataSourceId: 0, //TODO: Need data Source ID
        companyActiveStatus: 0, //TODO: Need Company Status
        createdDate: 0, //TODO: Need Created Date
        createdByEmployeeID: 0, //TODO: Need Created By Employee ID
        tradingType: this.details.tradingType,
        natureOfBusiness: this.details.businessType,
      }
      try {
        const response = await api.post("updateCompany", companyData)
        if (response && response.status === 200) {
          this.saveAndContinue()
          this.fetchCompanyDetails()
        }
      } catch (error) {
        this.somethingWentWrong()
      }
    },

    async updateCreditScoreAndContinue() {
      this.showCreditSafeError = false

      if (this.selectedCreditMode === null) {
        this.showCreditSafeError = true
        return false
      }

      let apiUrl = ""
      let creditData = {}

      if (this.selectedCreditMode === 1) {
        apiUrl = "updateCreditScore"

        if (
          this.creditSafeCompanyName === "" ||
          this.creditScore === "" ||
          this.creditLimit === "" ||
          this.details.creditSafeID === "" ||
          this.creditSafeCompanyName === null ||
          this.creditScore === null ||
          this.creditScore === "0" ||
          this.creditScore === 0 ||
          this.creditScore >= 101 ||
          this.creditLimit === null ||
          this.details.creditSafeID === null
        ) {
          const form = {
            creditSafeCompanyName: this.creditSafeCompanyName,
            creditScore: this.creditScore,
            creditLimit: this.creditLimit,
            creditSafeID: this.details.creditSafeID,
          }
          this.formHasErrors = false

          Object.keys(form).forEach((f) => {
            if (!this.form[f]) this.formHasErrors = true

            this.$refs[f].validate(true)
          })

          return false
        }

        this.details.creditSafeScore = parseInt(this.creditScore)
        this.details.creditSafeLimit = parseInt(this.creditLimit)
        creditData = {
          companyId: this.details.companyId,
          creditScore: this.details.creditSafeScore,
          limit: this.details.creditSafeLimit,
          currentUserId: this.userId,
          creditSafeCompanyName: this.creditSafeCompanyName,
          creditSafeId: this.details.creditSafeID,
        }
      } else if (this.selectedCreditMode === 2) {
        apiUrl = "updateExperian"
        if (
          this.experianCompanyName === "" ||
          this.experianCompanyName === null ||
          this.experianScore === "" ||
          this.experianScore === null ||
          this.experianScore === "0" ||
          this.experianScore === 0 ||
          this.experianScore >= 1001 ||
          this.experianLimit === null ||
          this.experianLimit === ""
        ) {
          const form = {
            experianCompanyName: this.experianCompanyName,
            experianScore: this.experianScore,
            experianLimit: this.experianLimit,
          }
          this.formHasErrors = false

          Object.keys(form).forEach((f) => {
            if (!this.form[f]) this.formHasErrors = true

            this.$refs[f].validate(true)
          })

          return false
        }

        this.details.experianScore = parseInt(this.experianScore)
        this.details.experianLimit = parseInt(this.experianLimit)
        creditData = {
          companyId: this.details.companyId,
          experianScore: this.details.experianScore,
          experianLimit: this.details.experianLimit,
          experianEmployeeID: this.userId,
          experianScoreDate: this.details.experianScoreDate,
          experianCompanyName: this.experianCompanyName,
        }
      } else {
        apiUrl = "updateCreditScoreNone"
        creditData = {
          companyID: this.details.companyId,
          experianScore: 0,
          experianLimit: 0,
          experianEmployeeID: this.userId,
          experianScoreDate: 0,
          experianCompanyName: "",
          experianEmployeeName: "",
          noteMessage: "Credit Score updated to NULL",
          saleId: 0,
        }
      }

      try {
        const response = await api.post(`/${apiUrl}`, creditData)
        if (response && response.status === 200) {
          this.saveAndContinue()
          this.fetchContractInfoSuppliers()
        }
      } catch (error) {
        this.somethingWentWrong()
      }
    },

    bankNameChanged() {
      this.payment.bankName = this.payment.bankName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    accountNameChanged() {
      this.payment.accountName = this.payment.accountName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },

    isSortCodeNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.payment &&
          this.payment.sortCode &&
          this.payment.sortCode.length >= 6
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    isAccountNoNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.payment &&
          this.payment.accountNumber &&
          this.payment.accountNumber.length >= 8
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    async savePaymentAndContinue() {
      if (
        this.payment.typeId === 1 &&
        (this.payment.bankName === "" ||
          this.payment.accountName === "" ||
          this.payment.accountNumber === "" ||
          this.payment.sortCode === "" ||
          this.errorMsg)
      ) {
        const form = {
          sortCode: this.payment.sortCode,
          accountNumber: this.payment.accountNumber,
          bankName: this.payment.bankName,
          accountName: this.payment.accountName,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }
      const filteredPayment = this.paymentTypesList.filter(
        (paymentType) => paymentType.value === this.payment.typeId
      )
      const paymentData = {
        id: this.payment.id,
        type: filteredPayment ? filteredPayment[0].text : "",
        typeId: this.payment.typeId ? this.payment.typeId : 0,
        bankName: this.payment.bankName,
        sortCode: this.payment.sortCode,
        accountName: this.payment.accountName,
        accountNumber: this.payment.accountNumber, // TODO: When getting the payment method the account number is coming the same as Account Name. I think there is a bug in saving the record.
        addressId: this.payment.addressId,
      }
      try {
        const response = await api.post("savePayment", paymentData)
        if (response && response.status === 200) {
          this.saveAndContinue()
        }
      } catch (error) {
        this.somethingWentWrong()
      }
    },

    async saveSignificantPersonAndContinue() {
      let noteMessage = ""

      if (
        this.selectedSignificantPerson.title !==
        this.dbSelectedSignificantPerson.title
      ) {
        noteMessage += `Title changed from ${
          this.dbSelectedSignificantPerson.title
            ? this.dbSelectedSignificantPerson.title
            : ""
        } to ${this.selectedSignificantPerson.title}<br>`
      }

      if (
        this.selectedSignificantPerson.firstName !==
        this.dbSelectedSignificantPerson.firstName
      ) {
        noteMessage += `First Name changed from ${
          this.dbSelectedSignificantPerson.firstName
            ? this.dbSelectedSignificantPerson.firstName
            : ""
        } to ${this.selectedSignificantPerson.firstName}<br>`
      }

      if (
        this.selectedSignificantPerson.lastName !==
        this.dbSelectedSignificantPerson.lastName
      ) {
        noteMessage += `Last Name changed from ${
          this.dbSelectedSignificantPerson.lastName
            ? this.dbSelectedSignificantPerson.lastName
            : ""
        } to ${this.selectedSignificantPerson.lastName}<br>`
      }

      if (
        this.selectedSignificantPerson.jobTitle !==
        this.dbSelectedSignificantPerson.jobTitle
      ) {
        noteMessage += `Job Title changed from ${
          this.dbSelectedSignificantPerson.jobTitle
            ? this.dbSelectedSignificantPerson.jobTitle
            : ""
        } to ${this.selectedSignificantPerson.jobTitle}<br>`
      }

      if (
        this.selectedSignificantPerson.contactNumber !==
        this.dbSelectedSignificantPerson.contactNumber
      ) {
        noteMessage += `Contact Number changed from ${
          this.dbSelectedSignificantPerson.contactNumber
            ? this.dbSelectedSignificantPerson.contactNumber
            : ""
        } to ${this.selectedSignificantPerson.contactNumber}<br>`
      }

      if (
        this.selectedSignificantPerson.emailAddress !==
        this.dbSelectedSignificantPerson.emailAddress
      ) {
        noteMessage += `Email changed from ${
          this.dbSelectedSignificantPerson.emailAddress
            ? this.dbSelectedSignificantPerson.emailAddress
            : ""
        } to ${this.selectedSignificantPerson.emailAddress}<br>`
      }

      const significantPersonData = {
        // altContactNumber: this.selectedSignificantPerson.altContactNumber,
        companyId: this.selectedSignificantPerson.companyId,
        contactId: this.selectedSignificantPerson.contactId,
        contactNumber: this.selectedSignificantPerson.contactNumber,
        currentUserId: this.selectedSignificantPerson.currentUserId,
        dob: this.selectedSignificantPerson.dob,
        emailAddress: this.selectedSignificantPerson.emailAddress,
        firstName: this.selectedSignificantPerson.firstName,
        jobTitle: this.selectedSignificantPerson.jobTitle,
        lastName: this.selectedSignificantPerson.lastName,
        leadId: this.selectedSignificantPerson.leadId
          ? this.selectedSignificantPerson.leadId
          : 0,
        primaryContact: this.selectedSignificantPerson.primaryContact,
        securityPassword: this.selectedSignificantPerson.securityPassword
          ? this.selectedSignificantPerson.securityPassword
          : "",
        title: this.selectedSignificantPerson.title,
        noteMessage:
          noteMessage === ""
            ? ""
            : `Contact Updated - ${this.selectedSignificantPerson.title} ${this.selectedSignificantPerson.firstName} ${this.selectedSignificantPerson.lastName}<br>${noteMessage}`,
      }
      try {
        const self = this
        const response = await api.post("updateContact", significantPersonData)
        self.errorMsg = ""
        self.errorMsgCC = ""
        if (response.status === 200) {
          let error = false
          if (isNaN(parseFloat(response.data))) {
            error = true
            if (response.data.includes("alternate")) {
              self.errorMsg = response.data
            } else {
              self.alreadyExistingNumber =
                self.selectedSignificantPerson.contactNumber
              self.errorMsgCC = response.data
            }
            if (error) {
              return false
            }
            // this.somethingWentWrong(error)
            // return false
          }
          this.saveAndContinue()
        } else {
          this.somethingWentWrong()
        }
      } catch (error) {
        this.somethingWentWrong()
      }
    },
    async fetchAddressesByContactId(contactId) {
      if (contactId) {
        const response = await api.get(`addressesByContactId/${contactId}`)
        this.contactAddresses = response.data
      }
    },
    async saveDOBAndContinue() {
      this.dirtOfBirthError = false
      this.contacatAddressCountError = false

      this.fetchAddressesByContactId(this.selectedSignificantPerson.contactId)

      if (
        this.details.tradingTypeId === 6 ||
        this.details.tradingTypeId === 8
      ) {
        if (
          (this.dob === null || this.dob === "" || this.dobText === "") &&
          !this.isDobTextChanged
        ) {
          this.dirtOfBirthError = true
          return false
        }
        if (!this.contactAddresses.length) {
          this.contacatAddressCountError = true
          return false
        }
      }
      if (
        (this.dob === null || this.dob === "" || this.dobText === "") &&
        !this.isDobTextChanged
      ) {
        this.saveAndContinue()
        return
      }
      const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
      const dobText = this.dobText.split("/").reverse().join("-")
      const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
      const diff = dayjs(currentDOB).diff(maxDate, "day")
      if (diff >= 1) {
        const form = {
          dobText: this.dobText,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })

        return false
      }

      const dobData = {
        contactId: this.selectedSignificantPerson.contactId,
        dob: dayjs(this.dob).unix(),
      }
      try {
        const response = await api.post("updateDob", dobData)
        if (response && response.status === 200) {
          this.saveAndContinue()
        } else {
          this.somethingWentWrong()
        }
      } catch (error) {
        this.somethingWentWrong()
      }
    },
    uploadEacConfirmed(saleId) {
      console.log("File Uploaded")
      const formData = new FormData()
      for (var i = 0; i != this.eacConfirmedFile.length; i++) {
        formData.append("files", this.eacConfirmedFile[i])
      }
      formData.append("saleID", saleId)
      formData.append("eacObtainedID", this.eacOptionSelected)
      formData.append("addedBy", this.userId)
      formData.append("totalEac", this.totalEac)
      const response = api.post("uploadEACFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (response.status === 200) {
        console.log("File Uploaded")
        this.eacConfirmedFile = null
      }
    },

    uploadCedConfirmed(saleId) {
      const formData = new FormData()
      formData.append("file", this.cedConfirmedFile)
      formData.append("saleID", saleId)
      formData.append("cedObtainedId", this.cedOptionSelected)
      formData.append("addedBy", this.userId)
      const response = api.post("uploadCedObtainedProofFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (response.status === 200) {
        this.cedOptionSelected = 0
        this.cedConfirmedFile = null
      }
    },

    async submitContract(isEform = false) {
      this.isSubmitting = true

      // if(!this.soldSupplierId || !this.selectedMeterType || this.csd === '' || this.termInMonths === '' || this.termInMonths > 60 || (this.termInMonths * 30) >= this.window) {
      if (
        (!this.soldSupplierId ||
          !this.selectedMeterType ||
          !this.csdText ||
          !this.termInMonths ||
          this.termInMonths > 60) &&
        this.meterTypeId !== 2705
      ) {
        const form = {
          soldSupplierId: this.soldSupplierId,
          selectedMeterType: this.selectedMeterType,
          csdText: this.csdText,
          termInMonths: this.termInMonths,
          eacOptionSelected: this.eacOptionSelected,
          cedOptionSelected: this.cedOptionSelected,
        }
        this.formHasErrors = false

        this.isSubmitting = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      if (
        this.meterTypeId === 2705 &&
        (!this.csdText ||
          !this.termInMonths ||
          this.termInMonths > 60 ||
          !this.soldSupplierId ||
          !this.currentRetailerPrice ||
          !this.soldPrice ||
          !this.standardCommission ||
          !this.upliftCommission)
      ) {
        const form = {
          csdText: this.csdText,
          termInMonths: this.termInMonths,
          upliftCommission: this.upliftCommission,
          standardCommission: this.standardCommission,
          soldSupplierId: this.soldSupplierId,
          currentRetailerPrice: this.currentRetailerPrice,
          soldPrice: this.soldPrice,
        }
        this.formHasErrors = false

        this.isSubmitting = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      const contractEndDate = this.ced
      const tmpContractEndDate = contractEndDate.split("/").reverse().join("-")
      const currentCED = dayjs(tmpContractEndDate).format("YYYY-MM-DD")

      let finalContractEndDate = currentCED
      if (finalContractEndDate === "Invalid Date") {
        finalContractEndDate = ""
      }

      const selectedCampaign =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      const isFixedCommission = this.fixedCommission
        ? this.resultFCEst
        : this.resultEstInput
      const contractType =
        this.masterContractType === 1
          ? "Renewal"
          : this.masterContractType === 2
          ? "Acquisition"
          : "New Mover"
      const contractIcon =
        this.masterContractType === 1
          ? "fas fa-undo"
          : this.masterContractType === 2
          ? "fas fa-arrow-right"
          : "fas fa-truck"
      console.log(this.csd, "csdBefore")
      const contractData = {
        meterID: this.meterId,
        companyID: this.details.companyId,
        meterTypeID: this.meterTypeId,
        currentSupplierID: this.supplierId,
        saleType: selectedCampaign,
        masterContractType: this.masterContractType,
        paymentID: this.paymentInfo.id,
        contractTypeID: this.selectedContractFrom,

        addressId: this.addressId,
        csd: dayjs(this.csd).unix(),
        term: parseInt(this.termInMonths),
        ced: dayjs(finalContractEndDate).unix(),
        closerId: this.employeeDetails.employeeId,
        significantId: this.significantPersonContactId,
        soldSupplierId: this.soldSupplierId,
        agreedContractId: this.selectedAgreedBy,
        lgDeclaredComms: this.lgDeclaredComms,
        lgLiveComms: this.lgLiveComms,
        clDeclaredComms: this.clDeclaredComms,
        clLiveComms: this.clLiveComms,
        clDeclCLLiveCommsaredComms: 0,
        processedContractValue: isFixedCommission,
        declaredContractValue: this.lgDeclaredValue,
        userId: this.userId,
        quoteTypePriceList: this.quoteTypePriceList,
        isFixedComm: this.fixedCommission,
        eacObtainedId: this.eacOptionSelected,
        cedObtainedId: this.cedOptionSelected,
        acquisitionReasonId:
          this.acquisitionReasonId !== null ? this.acquisitionReasonId : 0,
        contractType: contractType,
        contractTypeIcon: contractIcon,
        fpp:
          this.company === "utility hive" &&
          this.masterContractType === 2 &&
          this.meterTypeId !== 2705
            ? this.fpp
            : false,
        currentRetailerPrice:
          this.meterTypeId === 2705 ? this.currentRetailerPrice : 0,
        soldPrice: this.meterTypeId === 2705 ? this.soldPrice : 0,
        savingValue: this.meterTypeId === 2705 ? this.savings : 0,
        savingPercent: this.meterTypeId === 2705 ? this.savingsPercentage : 0,
        upliftCommission: this.meterTypeId === 2705 ? this.upliftCommission : 0,
        standardCommission:
          this.meterTypeId === 2705 ? this.standardCommission : 0,
        totalEstContractValue:
          this.meterTypeId === 2705 ? this.waterTotalEstContractValue : 0,
      }
      console.log(contractData)
      console.log(isEform)

      // if(isEform) {
      //   this.postEFormDetails(contractData)
      //   this.dialog = false
      //   this.openForm({ formName: "e-form", edit: false })

      // } else {

      const response = await api.post("submitContract", contractData)
      console.log("Response", response.data)
      const saleId = response.data
      if (response && response.status === 200) {
        changesSaved("Contract Submitted Successfully")
        if (this.meterTypeId !== 2705) {
          this.uploadCedConfirmed(saleId)
        }
        if (this.eacOptionSelected !== 427 && this.meterTypeId !== 2705) {
          this.uploadEacConfirmed(saleId)
        }
        setTimeout(() => {
          this.fetchMetersAfterRemove(this.selectedAddress.addressId)
        }, 1000)
        this.isSubmitting = false
        this.dialog = false
      } else {
        this.somethingWentWrong()
        this.isSubmitting = false
        this.dialog = false
      }
      // }
    },
    async validateSignificantPersonAndContinue() {
      const contactNumber = this.selectedSignificantPerson.contactNumber

      // if (altContactNumber === '' || altContactNumber.length === 0) this.formHasErrors = false
      if (!this.significantPersonContactId) {
        const form = {
          significantPersonContactId: this.significantPersonContactId,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      if (
        contactNumber !== null &&
        contactNumber !== "" &&
        (contactNumber.length < 10 ||
          /^(00|034|084|087|04|05|06|09)/.test(contactNumber))
      ) {
        this.formHasErrors = true

        this.$refs.contactNumber.$refs["contactNumber"].validate(true)
        return false
      }
      this.saveSignificantPersonAndContinue()
      await this.fetchContactAddresses(this.selectedSignificantPerson.contactId)
    },
    async validateAndContinue() {
      console.log("one")
      if (
        !this.selectedAgreedBy ||
        !this.selectedContractFrom ||
        !this.selecedSecurityPassword ||
        this.selecedSecurityPassword.length < 6
      ) {
        const form = {
          selectedContractFrom: this.selectedContractFrom,
          selectedAgreedBy: this.selectedAgreedBy,
          selecedSecurityPassword: this.selecedSecurityPassword,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })

        return false
      }

      if (
        this.selectedContact[0].securityPassword !==
        this.selecedSecurityPassword
      ) {
        const selectedContact = this.selectedContact[0]
        const noteMessage = `Security Password updated for ${selectedContact.title} ${selectedContact.firstName} ${selectedContact.lastName}`
        const details = {
          significantPersonId: this.selectedContact[0].contactId,
          password: this.selecedSecurityPassword,
          noteMessage: noteMessage,
          companyId: this.companyId,
          currentUserId: this.userId,
        }

        console.log(details)

        const response = await api.post(
          `updateWebSignificantPersonPassword`,
          details
        )
        if (response && response.status === 200) {
          changesSaved(`Password Updated`)
          this.fetchContacts()
          this.fetchNotes()
        } else {
          somethingWentWrong()
        }
      }
      this.saveAndContinue()
    },
    saveAndContinue() {
      changesSaved()

      this.e1++
    },
    somethingWentWrong(error = false) {
      somethingWentWrong()
      if (!error) {
        this.e1++
      }
    },

    async fetchContractMeterTypes() {
      this.profileClass = this.pc
      if (this.profileClass === null || this.profileClass === "") {
        this.profileClass = "0"
      }
      const response = await api.get(
        `contractMeterTypes/${this.meterTypeId}/${this.profileClass}`
      )
      this.contractMeterTypesList = response.data.map((option) => ({
        value: option.id,
        text: option.value,
      }))
    },
    async fetchTermTypeList() {
      const response = await api.get(`selectTerm/${this.soldSupplierId}`)
      console.log(response)
      this.TermTypesList = response.data.map((option) => ({
        value: option.id,
        text: option.value,
      }))
    },
    async fetchContractInfoSuppliers() {
      if (this.payment == null) {
        return
      }
      const selectedCampaign =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      const param = {
        meterTypeId: this.meterTypeId,
        paymentMethod: this.payment.typeId,
        creditScore: parseInt(
          this.selectedCreditMode === 2 ? this.experianScore : this.creditScore
        ),
        creditLimit: parseInt(
          this.selectedCreditMode === 2 ? this.experianLimit : this.creditLimit
        ),
        contractType: this.contractType,
        campaign: selectedCampaign,
        isExperian: this.selectedCreditMode === 2 ? true : false,
      }
      const response = await api.post(`contractInfoSuppliers`, param)
      let responseData = response.data
      if (this.contractType === 1) {
        responseData = responseData.filter(
          (item) => item.id !== this.supplierId
        )
      }
      this.suppliers = responseData.map((option) => ({
        value: option.id,
        text: option.value,
      }))
    },
    save() {
      console.log("save")
    },

    async getEACGuide() {
      if (this.selectedMeterType) {
        this.eacGuidedValue = 0
        this.isInternalEACGuideSelected = false
        const param = {
          masterContractTypeId: this.masterContractType,
          meterTypeId: this.meterTypeId,
          pc: this.selectedMeter.pc,
          natureOfBusinessSection: this.details.businessType,
          quoteTypeId: this.selectedMeterType,
        }
        console.log(param)
        const res = await api.post(`getEACGuide`, param)
        if (res && res.status === 200) {
          console.log(res.data)
          if (res.data !== null && res.data.length > 0) {
            this.eacGuidedValue = res.data[0].eac
            this.isInternalEACGuideSelected = true
          }
        }
      }
    },

    onChange(contactId) {
      const filterContact = this.contacts.filter(
        (contact) => contact.contactId === contactId
      )
      this.dbSelectedSignificantPerson = { ...filterContact[0] }
      this.selectedSignificantPerson = filterContact[0]
      const dob = filterContact[0].dob
      if (dob !== undefined) {
        this.dob = dob !== 0 ? dayjs(dob * 1000).format("YYYY-MM-DD") : ""
        if (this.dob === "") {
          this.dob = dayjs().subtract(18, "year").format("YYYY-MM-DD")
        }
        if (dob) {
          this.dobText = dayjs(this.dob).format("DD/MM/YYYY")
          if (this.dobText === "Invalid Date") {
            this.dobText = ""
          }
        } else {
          this.dobText = ""
        }
      }
    },
    selectEAC() {
      if (this.eacOptionSelected === 427) {
        this.eacConfirmedFile = null
        this.getEACGuide()
      } else {
        this.isInternalEACGuideSelected = false
      }
    },
    validateContractType() {
      this.firstTime = true
      console.log(this.contractType, this.e1)
      if (this.e1 === 1) {
        this.renewalPaymentTypeList = this.paymentTypesList.slice() // create a copy of the original array
        this.renewalPaymentTypeList = this.renewalPaymentTypeList.filter(
          (obj) => obj.value !== 3
        )
      }

      if (this.payment.typeId === 3 && this.contractType !== 0 && this.e1 > 3) {
        this.payment.typeId = 1
      }
      if (this.e1 < 4) {
        this.payment.typeId = 1
      }

      console.log(this.payment.typeId)
      this.csdText = null
      this.contractType === null
        ? (this.contractTypeValidationError = true)
        : (this.contractTypeValidationError = false)
      if (!this.contractTypeValidationErro) {
        this.getSupplierUplift()
      }
      if (
        !this.fixedComm ||
        this.fixedComm === 0 ||
        (this.fixedComm === 1 && !this.fixedCommission)
      ) {
        this.getEACGuide()
      }

      if (this.eacOptionSelected === 427) {
        this.getEACGuide()
      }
    },
    async fetchEacObtained() {
      const response = await api.get("eacObtained")
      if (response && response.status === 200) {
        this.eacOptions = response.data
      }
    },

    async fetchCedObtained() {
      const response = await api.get("getCedObtainedType")
      if (response && response.status === 200) {
        this.cedOptions = response.data
      }
    },

    async fetchAcquisitionReasons() {
      const response = await api.get("acquisitionReasons")
      if (response.status === 200) {
        this.acquisitionReasons = response.data
      } else {
        console.log(response)
      }
    },
    changeMeterType() {
      this.getSupplierUplift()
      this.changedMeterType = !this.changedMeterType
      console.log(this.changedMeterType)
      if (this.eacOptionSelected === 427) {
        this.getEACGuide()
      }
    },
    async changeSuppliers() {
      console.log(this.soldSupplierId, "soldSupplierId")
      this.changedMeterType = !this.changedMeterType

      this.fetchTermTypeList()
      this.showEFormBtn = false
      const saleTypeId =
        this.company === "utility hive"
          ? 2
          : this.company === "sub broker"
          ? 3
          : 1
      const res = await api.get(
        `getSignableURLForContracts/${saleTypeId}/${this.meterTypeId}/${this.soldSupplierId}`
      )
      if (res && res.status === 200) {
        if (res.data) this.showEFormBtn = true
      }

      this.getSupplierUplift()
      if (this.eacOptionSelected === 427) {
        this.getEACGuide()
      }
    },
  },
}
</script>
<style>
.generator {
  font-size: 12px;
  color: #9e9e9e;
  line-height: 16px;
}
.text-title {
  font-size: 12px;
  color: #30425a;
  line-height: 16px;
}

.sub-broker-color,
.sub-broker-color--text {
  border-color: #bcc9f7 !important;
  color: #3a509b !important;
  background-color: #bcc9f7 !important;
  transition: 0.3s;
}
</style>
