<template>
  <v-dialog v-model="visible" width="1200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="info"
        depressed
        icon
        small
        @click="previousSalesOpened"
      >
        <!-- <v-icon  small>mdi-receipt</v-icon> -->
        <i class="fas fa-archive" style="font-size: 14px; color: #9575cd"></i>
      </v-btn>
    </template>

    <v-card>
      <v-card-title style="background-color: #9575cd; color: #fff !important">
        SALES ARCHIVE
      </v-card-title>
      <v-card-subtitle
        class="pt-1 pb-2"
        style="background-color: #9575cd; color: #fff !important"
      >
        Sales Archive Information
      </v-card-subtitle>
      <v-card-text class="mt-2">
        <v-row>
          <v-col class="d-flex justify-center" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-headers text-left">Company Details</th>
                    <th class="text-headers text-left">Current Supplier</th>
                    <th class="text-headers text-left">Sold Supplier</th>
                    <th class="text-headers text-center">Status</th>
                    <th class="text-headers text-left" style="width: 220px">
                      Contract Details
                    </th>
                    <th class="text-headers text-left" style="width: 220px">
                      Contract Value
                    </th>
                    <th class="text-headers text-left" style="width: 250px">
                      Sales Log
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="p-2"
                    style="height: 90px"
                    v-for="previousSale in sortedItems"
                    :key="previousSale.saleId"
                  >
                    <td style="width: 250px">
                      <div>
                        <span
                          ><strong>{{ previousSale.companyName }}</strong></span
                        >
                      </div>
                      <div>
                        <span>
                          <strong>Sale ID: </strong>
                          <a
                            :class="
                              permission.wfallowsaleidlink
                                ? 'blue--text cursor-pointer'
                                : 'disabled-anchor text-black'
                            "
                            :href="
                              'http://hive.utilityhive.com/sales/sales-form?SaleID=' +
                              previousSale.saleId
                            "
                            button
                            style="text-decoration: none; opacity: 1 !important"
                            target="_blank"
                          >
                            {{ previousSale.saleId }}
                          </a>
                        </span>
                      </div>
                      <div>
                        <span
                          ><strong>Processed Date: </strong>
                          <span
                            :class="
                              !previousSale.processedDate ? 'red--text' : ''
                            "
                          >
                            <i
                              v-if="!previousSale.processedDate"
                              class="fas fa-handshake-alt-slash red--text ml-2"
                            ></i>
                            {{
                              previousSale.processedDate === 0 ||
                              previousSale.processedDate === "0"
                                ? "Not Processed"
                                : formattedDate(previousSale.processedDate)
                            }}
                          </span>
                        </span>
                      </div>
                    </td>
                    <!-- 13 -->
                    <td>
                      <div
                        class="estimate-container"
                        style="width: 110px; height: 50px"
                      >
                        <v-img
                          :src="supplierLogo(previousSale.currentSupplierId)"
                        ></v-img>
                      </div>
                    </td>
                    <td>
                      <div
                        class="estimate-container"
                        style="width: 110px; height: 50px"
                      >
                        <v-img
                          :src="supplierLogo(previousSale.soldSupplierId)"
                        ></v-img>
                      </div>
                    </td>
                    <!-- Status -->
                    <td style="min-width: 180px">
                      <div class="mt-5 pt-2">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <Statuses
                                :status="previousSale.status"
                                :previousSale="previousSale"
                              />
                            </div>
                          </template>
                          <span>{{ tooltipStatus(previousSale.status) }}</span>
                        </v-tooltip>
                        <!-- {{previousSale}} -->
                      </div>
                    </td>
                    <!-- Contract Details -->
                    <td style="width: 220px">
                      <ContractInfo
                        :master-type="previousSale.masterContractTypeId"
                        :csd="previousSale.startDate"
                        :ced="previousSale.endDate"
                        :term="previousSale.months"
                        :resell="previousSale.reSell"
                      />
                    </td>
                    <!-- Contract Value -->
                    <td style="width: 250px">
                      <div class="d-flex flex-column text-left">
                        <span
                          >Sold Value:
                          {{ formatPrice(previousSale.soldContractValue) }}
                        </span>
                        <span
                          >Processed Value:
                          {{ formatPrice(previousSale.processedValue) }}
                        </span>
                      </div>
                    </td>
                    <!-- Sale Logs -->
                    <td class="">
                      <div class="mt-1" style="width: 180px">
                        <UserAvatar
                          class="d-flex justify-content-start"
                          small
                          :portrait="previousSale.leadGeneratorId"
                          :full-name="previousSale.leadGeneratorName"
                          label="Lead Generator"
                        ></UserAvatar>
                      </div>
                      <div class="mt-2 mb-1" style="width: 180px">
                        <UserAvatar
                          class="d-flex justify-content-start"
                          small
                          :portrait="previousSale.closerId"
                          :full-name="previousSale.closerName"
                          label="Closer"
                        ></UserAvatar>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"

import UserAvatar from "@/components/user-avatar"
import api from "@/store/api"
import dayjs from "dayjs"
import ContractInfo from "@/components/contract-info.vue"
import Statuses from "@/components/statuses.vue"

// import LoadingSpinner from "@/components/loading-spinner"
// import EmptyCard from "@/components/empty-card"
// import api from "@/store/api"
// import { chunkArray } from "@/store/helpers/utils"
// import dayjs from "dayjs"

export default {
  components: {
    UserAvatar,
    ContractInfo,
    Statuses,
  },
  props: {
    supplyNumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      previousSales: [],
      permission: {
        wfallowsaleidlink: false,
      },
      // regReports: [],
    }
  },
  computed: {
    ...mapGetters("employee", ["userId"]),
    sortedItems() {
      const sales = this.previousSales
      return sales.sort((a, b) => {
        return b.processedDate - a.processedDate
      })
    },
  },
  created() {
    this.fetchPermission()
  },
  methods: {
    async fetchPermission() {
      const pagePermission = this.permission
      const res = await api.get(`permission/${this.userId}`)
      if (res.status === 200) {
        const result = res.data
        for (var p in result) {
          console.log(result[p])
          pagePermission[result[p].name] = result[p].hasPermission
        }
      }
    },
    async previousSalesOpened() {
      const res = await api.get(`previousSales/${this.supplyNumber}`)
      if (res && res.status === 200) {
        this.previousSales = res.data
      }
    },
    allowDetails(employeeId) {
      if (employeeId) return true
      // return this.userId === employeeId || this.permission.allowsaleidlink
    },
    supplierLogo(id) {
      return `https://crm.utilityhive.com/Img/SupplierLogos/${id}.jpg`
    },
    formattedDate(date) {
      return dayjs(date * 1000).format("DD/MM/YYYY")
    },
    formatPrice(value) {
      var val = (value / 1).toFixed(2)
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tooltipStatus(status) {
      if (status === "qualityCheck") {
        return "Quality Check"
      }
      if (status === "failed") {
        return "Failed"
      }
      if (status === "live") {
        return "Live"
      }
      if (status === "declared") {
        return "Declared"
      }
      if (status === "sold") {
        return "Sold"
      }
      return "Processing"
    },
  },
}
</script>

<style scoped>
.text-headers {
  font-family: Inter, Rubik;
  font-weight: 700;
  color: #ea5791 !important;
  font-size: 1rem !important;
}
.disabled-anchor {
  pointer-events: none;
  opacity: 75%;
  color: black !important;
}
</style>
